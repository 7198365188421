﻿/* global require, gtag */
// depends on: efw.broadcast.js
// depends on: jquery.event.gevent.js
// depends on: js-cookie.js
// depends on: efw.local.js
// depends on: efw.local.data.js
// depends on: efw.formvalid.js
// depends on: efw.dialog.js
// depends on: efw.trackEvents.js
(function ($) {
    // globals
    var Cookies = require('js-cookie');
    var _ = require('lodash');
    var JSON_CONTENT = "application/json; charset=utf-8";
    var FORM_CONTENT = "application/x-www-form-urlencoded";
    var TOKEN_KEY = "efw.logon.token";
    var RAW_KEY = "efw_raw";
    var FAVORITES_KEY = "Favorites";
    var SESSION_KEY = "Sessions";
    var USER_KEY = "UserName";
    var ORG_KEY = "Organization";
    var ROLE_KEY = "Roles";
    var ACCESS_TOKEN_KEY = "access_token";
    //var STOKEN_KEY = "stoken";
    var EXPIRES_KEY = ".expires";
    var ORG_COOKIE = "wid_org";
    var ROLES_COOKIE = "wid_roles";
    var ORG_SCOPE = { expires: 90, path: '/', SameSite: 'None', Secure: true };
    var LOGON_COOKIE = "efw_logon2";
    var LOGON_COOKIE_PREFIX = "efw_logon_";
    var LOGON_SCOPE = { path: '/', SameSite: 'None', Secure: true }; /* session cookie */
    var PROSESSIONID_COOKIE = "_vsid";
    var MAX_SESSIONS = 8;
    //var PW_RANGE = [6, 100];
    var VAULT_SHARE_SESSION_DIALOG = '#vault-share-session';
    var cvSlot = 1;
    var cvScope = 1; // visitor-level
    var BASE_URL = window.location.protocol + "//" + window.location.host;
    var $editing = false;
    var OFFER_LOGON_URL = [
        {
            pathname: '/vault.htm', // notice navigation to the Vault
            requireHash: true      // don't offer navigation if there's a hash on the url
        }
    ];

    // DB101 logon plugin
    // Hang global functions on this
    $.fn.logon = function () { };

    // global functions
    // Disable caching of AJAX responses
    $.ajaxSetup({
        cache: false
    });

    // last chance error handler. Does not rely on UI.
    function AjaxError(jqXHR, exception) {
        var m = "general.fail.title"._local() + ": ";
        if (jqXHR.status === 0) {
            m += 'general.ajax.error.notconnected'._local();
        } else if (jqXHR.status === 401 || jqXHR.status === 403) {
            m += 'general.ajax.error.notauthorized'._local();
        } else if (jqXHR.status === 404) {
            m += 'general.ajax.error.notfound'._local();
        } else if (jqXHR.status === 500) {
            m += 'general.ajax.error.internal'._local();
        } else if (exception === 'parsererror') {
            m += 'general.ajax.error.parsererror'._local();
        } else if (exception === 'timeout') {
            m += 'general.ajax.error.timeout'._local();
        } else if (exception === 'abort') {
            m += 'general.ajax.error.abort'._local();
        } else {
            m += 'general.ajax.error.unknown'._local() + ": " + jqXHR.responseText;
        }
        return m;
    }

    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        // condition the search string. Sometimes polluted when copied-and-pasted from the reset email.
        if (sPageURL.indexOf('<') > 0) {
            sPageURL = sPageURL.substring(0, sPageURL.indexOf('<')).trim();
        }
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
        return "";
    }

    function getUrlParameterDecoded(sParam) {
        return decodeURIComponent(getUrlParameter(sParam));
    }

    // carefully close a dialog
    function closeDlgArea($handlers) {
        var $dlg = $handlers.dialogArea;
        if (null !== $dlg && typeof ($dlg) !== "undefined" && $dlg.hasClass('modal') && typeof ($dlg.modal) === "function")
            $dlg.modal('hide');
    }

    // parse model state errors from Web API
    //function ModelStateErrors(jqXHR) {
    //    if (jqXHR.status !== 400)   // bad request only
    //        return "";

    //    var errs = "";

    //    $.each(jqXHR.responseJSON.ModelState, function (e, i) {
    //        errs = errs + (errs.length > 0 ? "<br />" : "") + e;
    //    });
    //    return errs;
    //}

    // utility handler functions
    function _nullHandler(/*jqXHR, status, $handlers*/) {
        
    }

    function _alertHandler(jqXHR, status, /*$handlers*/) {
        alert(status);
    }

    function _alertFail(jqXHR, status, $handlers) {
        _alertHandler(jqXHR, "logon.fail.message"._local(), $handlers);
    }

    // standard handler to be called before dispatching to ajax
    function _startHandler(jqXHR, $handlers) {
        // start spinning
        if (null !== $handlers.dialogArea)
            $handlers.dialogArea.trigger('efw.spin');
    }

    // standard handler for declarative status result
    // pops up standard result dialog
    // can safely assume jqHXR.responseJSON.result is ok
    function _resultStatusHandler(jqXHR, status, $handlers) {
        closeDlgArea($handlers);

        $.gevent.publish('efw.result', jqXHR.responseJSON.result)
    }

    // handle info passed back with result: merge into stored data
    function _infoHandler(jqXHR /*, status, $handlers*/) {
        var $info = $.extend({}, _GetLocalData(), jqXHR.responseJSON.info);
        StoreToken($info);
    }

    // standard success handler
    function _successHandler(jqXHR, status, $handlers) {
        if ("undefined" !== typeof (jqXHR.responseJSON) && "undefined" !== typeof (jqXHR.responseJSON.info))
            _infoHandler(jqXHR, status, $handlers);

        if ("undefined" !== typeof (jqXHR.responseJSON) && "undefined" !== typeof (jqXHR.responseJSON.result))
            _resultStatusHandler(jqXHR, status, $handlers);
        else
            _alertHandler(jqXHR, "general.success.title"._local(), $handlers); // weak        
    }

    // standard handler for model state errors
    function _modelStateHandler(jqXHR, status, $handlers) {
        var $status = $handlers.statusArea;
        var modelState = jqXHR.responseJSON.ModelState;
        if (null === modelState || typeof (modelState) === "undefined" || null === $status || typeof ($status) === "undefined")
            return;

        $status.empty();
        $.each(modelState, function (k, v) {
            $.each(v, function (i, v2) {
                AppendError($status, v2);
            });
        });
    }

    // standard handler for expired sessions
    function _expiredSessionHandler(jqXHR, status, $handlers) {
        closeDlgArea($handlers);
        DoLogoff();
        $.gevent.publish("efw.expired");
    }

    // standard last chance error handler
    function _lastChanceHandler(jqXHR, status/*, $handlers*/) {
        alert(AjaxError(jqXHR, status));
    }

    function _appendErrorHandler(jqXHR, status, $handlers) {
        var $status = $handlers.statusArea;
        if ($status) {
            AppendError($status, jqXHR.responseJSON.result._local());
        } else {
            _resultStatusHandler(jqXHR, status, $handlers);
        }
    }

    // override any of these
    var $standardHandlers = {
        statusArea: null,
        dialogArea: null,
        startHandler: _startHandler,
        successHandler: _successHandler,
        modelStateHandler: _modelStateHandler,
        resultStatusHandler: _resultStatusHandler,
        lastChanceHandler: _lastChanceHandler,
        expiredSessionHandler: _expiredSessionHandler,
        dispatchMap: null,
        site: -1,
        logonService: "",
        favoritesService: ""
    }

    // used by register and logon functions
    var _logonFail = function (jqXHR, status, $handlers) {
        AppendError($handlers.statusArea, (jqXHR.responseJSON?.error_description || "logon.fail.message")._local());
    }

    // standard error dispatcher.
    function _errorDispatcher(jqXHR, status, $handlers) {
        // 1) Model state errors
        if (jqXHR.status === 400) {
            if ("undefined" !== typeof (jqXHR.responseJSON) && "undefined" !== typeof (jqXHR.responseJSON.ModelState)) {
                $handlers.modelStateHandler(jqXHR, status, $handlers);
                return;
            }
        }
        // 2) Expired sessions.
        if ((401 === jqXHR.status || 403 === jqXHR.status) && IsLoggedIn()) {
            $handlers.expiredSessionHandler(jqXHR, status, $handlers);
            return;
        }
        // 3) Standard result responses
        if ("undefined" !== typeof (jqXHR.responseJSON) && "undefined" !== typeof (jqXHR.responseJSON.result)) {
            $handlers.resultStatusHandler(jqXHR, status, $handlers);
            return;
        }
        // 4) Status code map.
        if (null !== $handlers.dispatchMap && "undefined" !== typeof ($handlers.dispatchMap[jqXHR.status])) {
            $handlers.dispatchMap[jqXHR.status](jqXHR, status, $handlers);
            return;
        }
        // aw, crap.
        $handlers.lastChanceHandler(jqXHR, status, $handlers);
    }

    var $standardRegisterModel = { ConfirmSuccessUrl: BASE_URL + "/?mail-conf=true", ConfirmFailUrl: BASE_URL + "/?mail-conf=false" };

    var _identifier = function (s) { return s.replace(/\W/g, '_'); }

    function DoRegister(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = $.extend({}, $standardRegisterModel, model);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/Register",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            $.extend($handlers.dispatchMap, { 400: _logonFail });   // add default failure behavior
            // on logon success, acknowledge _registration_ success
            $handlers.successHandler = function () { _resultStatusHandler(jqXHR, textStatus, $handlers); }
            DoLogon({ username: $model.Email, password: $model.Password }, $handlers);
        }).fail(function (jqXHR, textStatus /*, errThrown*/) {
            _errorDispatcher(jqXHR, textStatus, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoRegisterProvisional(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = $.extend({}, $standardRegisterModel, model);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/RegisterProvisional",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            $handlers.successHandler(jqXHR, textStatus, $handlers);
        }).fail(function (jqXHR, textStatus /*, errThrown*/) {
            _errorDispatcher(jqXHR, textStatus, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoLogon($model, handlers, callback) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/Token",
            data: $.extend({ grant_type: "password" }, $model),  // don't stringify; allow jquery to convert to query string
            contentType: FORM_CONTENT,
            dataType: "json",
            processData: true,
            timeout: 10000,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            _StuffLogonData(data);
            $handlers.successHandler(jqXHR, textStatus, $handlers);
            $.gevent.publish('efw.logon');
        }).fail(function (jqXHR /*, textStatus, errorThrown*/) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
            if (callback)
                callback();
        });
    }

    function DoSendRoleEmail(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = model;
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Role/SendRoleEmail",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            $handlers.successHandler(jqXHR, textStatus, $handlers);
        }).fail(function (jqXHR, textStatus /*, errThrown*/) {
            _errorDispatcher(jqXHR, textStatus, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoCreateManagedRole(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = model;
        $model.SiteRegistered = $handlers.site;

        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Role/ManagedRole",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: 'json',
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            $handlers.successHandler(jqXHR, textStatus, $handlers);
        }).fail(function (jqXHR, textStatus /*, errThrown*/) {
            _errorDispatcher(jqXHR, textStatus, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function _StuffLogonData(data) {
        _StoreLocally(data, RAW_KEY); // raw copy without extra entries
        StoreToken(data);
        SetOrgCookie();
        TrackOrgVariable();
        AutosaveSession();
    }

    function DoChangePassword($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/ChangePassword",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            DoLogoff();
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoConfEmail(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = $.extend({}, $standardRegisterModel, model);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/SendConfirmationEmail",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoDisableAccount($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/Disable",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            if (!$model.Email || $model.Email === GetUser()) {
                // we just disabled our own account.
                DoLogoff();
            }
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoUnsubscribe(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = $.extend({}, $standardRegisterModel, model);
        $.ajax({
            type: "POST",
            url: `${$handlers.vaultService}/user/unsubscribe/${model.email}/${model.hash}/${model.type}`,
            data: "{}",
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, textStatus, jqXHR) {
            $handlers.successHandler(jqXHR, textStatus, $handlers);
        }).fail(function (jqXHR, textStatus /*, errThrown*/) {
            _errorDispatcher(jqXHR, textStatus, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoProfile(model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        var $model = $.extend({}, $standardRegisterModel, model);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/Profile",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            // edit and restore profile data
            var $data = $.extend(_GetLocalData(), data.info);
            if (!(ORG_KEY in data.info) && (ORG_KEY in $data))
                delete $data[ORG_KEY];
            StoreToken($data);
            SetOrgCookie();
            TrackOrgVariable();
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoResetPassword($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/ResetPassword",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoForgotPassword($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Account/ForgotPassword",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    function DoLogoff() {
        KillToken();
        //KillOrgCookie();
        KillLogonCookie();
        $.gevent.publish('efw.logout');
    }

    function AddOrg($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Organizations",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    //  { User = email, Role = role, Notify? }
    function AddToRole($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/UserRole/AddToRole",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    //  { User = email, Role = role }
    function RemoveFromRole($model, handlers) {
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/UserRole/RemoveFromRole",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }
    // $models = [{ RequestedRoleName, RequestReason }]
    function RequestRoles($models, handlers) {
        //console.log('RequestRoles: ' + JSON.stringify($models));
        var $handlers = $.extend({}, $standardHandlers, handlers);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/RoleRequest/CreateList",
            data: JSON.stringify($models),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            beforeSend: function (jqXHR) {
                $handlers.startHandler(jqXHR, $handlers);
            }
        }).done(function (data, status, jqXHR) {
            //console.log('RequestRole.done: ' + JSON.stringify($models));
            $handlers.successHandler(jqXHR, status, $handlers);
        }).fail(function (jqXHR, status) {
            console.log('RequestRole.fail: ' + JSON.stringify($models));
            _errorDispatcher(jqXHR, status, $handlers);
        }).always(function () {
            //console.log('RequestRole.always: ' + JSON.stringify($models));
            // stop spinning
            if (null !== $handlers.dialogArea)
                $handlers.dialogArea.trigger('efw.unspin');
        });
    }

    // [{ User = email, Roles = [r0, r1, r2]}]
    function SetUserRoles(opts, $model, $callback) {
        $.ajax({
            type: "POST",
            url: opts.logonService + "/api/UserRole/SetUserRoles?stateCode=" + (opts.stateCode || ''),
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $callback(data);
        }).fail(function () {
            $callback(null);
        });
    }

    function GetUserRoles(opts, filter, $each) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/UserRole?term=" + filter,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                $.each(data, $each);
            }
            // fail silently.
        });
    }

    function GetUserRolesByRole(opts, role, $each) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/UserRole/GetUserRolesByRole?role=" + role,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                $.each(data, $each);
            }
            // fail silently.
        });
    }

    var $allRoles = [];

    function _SortRoles(a, b) {
        // sort the roles by title...being smart about included numbers
        // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
        return (a.Title || a.Name || '').localeCompare((b.Title || b.Name || ''), undefined, { numeric: true, sensitivity: 'base' });
    }

    function GetAllRoles(opts, $callback) {
        if ($allRoles.len > 0) {
            $callback($allRoles);
            return;
        }

        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Role?stateCode=" + (opts.stateCode || ''),
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;
                data.sort(_SortRoles);
                $allRoles = data;   // cache results.
                $callback(data);
            }
            // fail silently.
        })
    }

    function GetAccountsByRole(opts, $callback) {
        if (!opts.role || 0 === opts.role.length) {
            $callback([]);
        }

        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Account/GetAccountsByRole?role=" + opts.role,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    $callback([]);
                $callback(data);
            },
            error: function (jqXHR, status) {
                console.error('GetAccountsByRole: ' + status);
                $callback([]);
            }
        })
    }

    function GetPendingRoleRequests(opts, $callback) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/RoleRequest/GetPending",
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;
                $callback(data);
            },
            error: function() {
                $callback(null);
            }
        })
    }

    function GetMyPendingRoleRequests(opts, $callback) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/RoleRequest/GetMyPending",
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;
                $callback(data);
            },
            error: function () {
                $callback(null);
            }
        })
    }

    // { RequestId: id }
    function ApproveRoleRequest(opts, $model, $callback) {
        $.ajax({
            type: "POST",
            url: opts.logonService + "/api/RoleRequest/Approve",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $callback(data);
        }).fail(function () {
            $callback(null);
        });
    }

    // [{ RequestId: id }, ...]
    function ApproveRoleRequests(opts, $model, $callback) {
        $.ajax({
            type: "POST",
            url: opts.logonService + "/api/RoleRequest/ApproveList",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $callback(data);
        }).fail(function () {
            $callback(null);
        });
    }

    // { RequestId: id, DeniedReason: string }
    function DenyRoleRequest(opts, $model, $callback) {
        $.ajax({
            type: "POST",
            url: opts.logonService + "/api/RoleRequest/Deny",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $callback(data);
        }).fail(function () {
            $callback(null);
        });
    }

    // [{ RequestId: id, DeniedReason: string }, ...]
    function DenyRoleRequests(opts, $model, $callback) {
        $.ajax({
            type: "POST",
            url: opts.logonService + "/api/RoleRequest/DenyList",
            data: JSON.stringify($model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $callback(data);
        }).fail(function () {
            $callback(null);
        });
    }

    function GetFilteredAccounts(opts, term, $callback) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Account/FilteredAccounts?term=" + term,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;
                $callback(data);
            },
            error: function (jqXHR, status) {
                $callback(null);
                console.log(AjaxError(jqXHR, status));
            }
        })
    }

    function GetNumAccounts(opts, $callback) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Account/NumAccounts",
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined == data)
                    return;

                $callback(data);
            }
        });
    }

    function GetManagedRoles(opts, $each, $empty) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Role/ManagedRoles/" + opts.stateCode,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                if (data.length > 0) {
                    // sort the roles by title...being smart about included numbers
                    data.sort(_SortRoles);
                    $.each(data, $each);
                } else {
                    if ($empty)
                        $empty();
                }
            }
            // fail silently.
        });
    }

    function GetMyManagedRoles(opts, $each, $empty) {
        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Role/MyManagedRoles/" + opts.stateCode,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                //console.log("GetMyManagedRoles: " + JSON.stringify(data));
                if (data.length > 0) {
                    // sort the roles by title...being smart about included numbers
                    data.sort(_SortRoles);
                    $.each(data, $each);
                } else {
                    $empty();
                }
            }
            // fail silently.
        });
    }

    function GetRoleMembers(role, opts, $each, $empty, $finally) {
        $.ajax({
            type: "GET",
            url: `${opts.logonService}/api/UserRole/RoleMembers?role=${role}`,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                //console.log("GetRoleMembers: " + JSON.stringify(data));
                if (data.length > 0) {
                    $.each(data, $each);
                } else {
                    $empty();
                }
                if ($finally) {
                    $finally();
                }
            }
            // fail silently.
        });
    }

    function PassOnRole($model, opts) {
        var $handlers = $.extend({}, $standardHandlers, opts);
        $.ajax({
            type: "POST",
            url: $handlers.logonService + "/api/Role/PassOnRole",
            data: JSON.stringify($model),
            dataType: "json",
            contentType: JSON_CONTENT,
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function (jqXHR) {
                $handlers.successHandler(jqXHR);
            },
            error: function (jqXHR, status) {
                _errorDispatcher(jqXHR, status, $handlers);
            }
        });
    }

    function PostFavorite($model, opts) {
        if (!IsLoggedIn()) {
            //$none.show();
            return;
        }
        const _success = function () {
            GetFavorites(opts);
        };

        $.ajax({
            type: "POST",
            url: opts.favoritesService + "/api/Favorites",
            data: JSON.stringify($model),
            dataType: "json",
            contentType: JSON_CONTENT,
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function () {
                _success();
            },
            error: function (jqXHR, status) {
                if (jqXHR.status === 409)
                    _success();
                else
                    console.log(AjaxError(jqXHR, status));
            }
        });
    }

    function DeleteFavorite(id, opts) {
        $.ajax({
            type: "DELETE",
            url: opts.favoritesService + "/api/Favorites/" + id.toString(),
            headers: { "Authorization": "Bearer " + GetToken() },
            success: function () {
                GetFavorites(opts);
            },
            error: function (jqXHR, status) {
                console.log(AjaxError(jqXHR, status));
            }
        });
    }

    //function GetInfo() {
    //    if (!IsLoggedIn()) {
    //        return;
    //    }
    //    $.ajax({
    //        type: "GET",
    //        url: LOGON_SERVICE + "/api/Account/UserInfo",
    //        headers: { "Authorization": "Bearer " + GetToken() },
    //        success: function (data) {

    //            $.gevent.publish('efw.favoritesUpdate');
    //        },
    //        error: function (jqXHR, status, errorThrown) {
    //            console.write(AjaxError(jqXHR, status));
    //        }
    //    })
    //}
    function GetOrgs(opts, callback) {
        var $args = (opts.site >= 0 ? "?site=" + opts.site.toString() : "");

        $.ajax({
            type: "GET",
            url: opts.logonService + "/api/Organizations" + $args,
            dataType: "json",
            success: function (data) {
                if (null == data || undefined === data)
                    return;

                callback(null, data);
            }
            // fail silently.
        });
    }

    function GetFavorites(opts) {
        if (!IsLoggedIn()) {
            return;
        }
        $.gevent.publish('efw.favoritesSeek');
        $.ajax({
            cache: false,
            type: "GET",
            url: opts.favoritesService + "/api/Favorites?site=" + opts.site.toString(),
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            _SetLocalField(data, FAVORITES_KEY);
            $.gevent.publish('efw.favoritesUpdate', data);
        }).fail(function (jqXHR) {
            // test expired session
            if ((401 === jqXHR.status || 403 === jqXHR.status) && IsLoggedIn()) {
                $standardHandlers.expiredSessionHandler(jqXHR, status, $standardHandlers);
                return;
            }
            $.gevent.publish('efw.favoritesUpdate', null);
        }).always(function () {
        });
    }

    function GetSavedSessions(opts) {
        if (!IsLoggedIn()) {
            return;
        }
        $.gevent.publish('efw.sessionsSeek');
        $.ajax({
            cache: false,
            type: "GET",
            url: opts.sessionService + "?max=" + MAX_SESSIONS,
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            _SetLocalField(data, SESSION_KEY);
            $.gevent.publish('efw.sessionsUpdate', data);
        }).fail(function () {
            $.gevent.publish('efw.sessionsUpdate', null);
        }).always(function () {
        });
    }

    function DeleteSavedSession($model, opts) {
        $.ajax({
            cache: false,
            type: "DELETE",
            url: opts.sessionService,
            data: JSON.stringify($model),
            dataType: "json",
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data) {
            $.gevent.publish('efw.sessionsReset', data);
        }).fail(function () {
            $.gevent.publish('efw.sessionsReset', null);
        }).always(function () {
        });
    }

    function MatchFavorite(tTool, tName, tUrl) {
        var $favs = _GetDataItem(FAVORITES_KEY);
        if (null === $favs || undefined === $favs)
            return null;

        var $isTool = ("undefined" !== typeof (tTool) && tTool === "tool");
        var $cur = null;
        var $url = tUrl; //window.location.pathname;

        $.each($favs, function (i, d) {
            if ($isTool ? MatchTool(d, $url, tName) : MatchPage(d, $url)) {
                $cur = d;
                return false;   // end loop
            }
        });

        return $cur;
    }

    function MatchPage(d, url) {
        return (d.url == url);
    }

    function MatchTool(d, url, tName) {
        let lurl = (url.indexOf('#') > 0 ? url.substring(0, url.indexOf('#')) : url);
        return (d.url == ((lurl + "#").replace("##", "#") + tName));
    }

    function IsFavoriteTool(d) {
        if (null !== d && typeof (d) !== "undefined")
            if (null !== d.url && typeof (d.url) !== "undefined")
                return (d.url.indexOf('#') > 0);
        return false;
    }

    // manipulation of logon token.
    function StoreToken(data) {
        if (null == data)
            return;

        _StoreLocally(data, TOKEN_KEY);
        SetLogonCookie(data);
    }

    // store object as JSON
    function _StoreLocally(d, k) {
        if (null != d) {
            var jD = JSON.stringify(d);
            localStorage.setItem(k, jD);
            //$.fn.efw$broadcast.postCommand('storeLocal', k, jD); // verb/key/value    // efw$broadcast obsolete
        }
    }

    function _SetLocalField(d, k) {
        var data = _GetLocalData();
        if (null !== data) {
            data[k] = d;
            StoreToken(data);
        }
    }

    //function _RemoveLocalField(k) {
    //    var data = _GetLocalData();
    //    if (null !== data) {
    //        try {
    //            delete data[k];
    //        }
    //        catch (e) {
    //            data[k] = undefined;
    //        }
    //    }
    //}

    function _GetLocalData() {
        var s = localStorage.getItem(TOKEN_KEY);
        return (s ? JSON.parse(s) : null);
    }

    //function _GetLocalRaw(k) {
    //    let s = localStorage.getItem(k);
    //    return (s || '');
    //}

    function _GetDataItem(key) {
        var d = _GetLocalData();
        return (null === d || !(key in d) ? null : d[key]);
    }

    function _GetDataBoolean(key) {
        var d = _GetDataItem(key);
        if (null === d)
            return false;
        if (!d)
            return false;
        if (d.toString().toLowerCase() === 'true')
            return true;
        return false;
    }

    //function _ValidString(s) {
    //    return (null === s || typeof (s) === "undefined" ? "" : s.toString());
    //}

    function GetToken() {
        return _GetDataItem(ACCESS_TOKEN_KEY);
    }

    //function GetSToken() {
    //    return _GetDataItem(STOKEN_KEY);
    //}

    function GetUser() {
        return _GetDataItem(USER_KEY);
    }

    function GetOrg() {
        var d = _GetLocalData();
        return (null === d || !(ORG_KEY in d) ? null : JSON.parse(d[ORG_KEY])); // painfully we must parse again
    }

    function GetRoles() {
        var d = _GetLocalData();
        return (null === d || !(ROLE_KEY in d) ? null : JSON.parse(d[ROLE_KEY]));
    }

    function GetRolesString() {
        return `|${GetRoles().join('|')}|`;
    }

    function IsInRole(rtest) {
        var roleFound = false;
        $.each(GetRoles(), function (i, r) {
            if (r == rtest)
                roleFound = true;
        });

        return roleFound;
    }

    function GetOrgKey() {
        var o = GetOrg();
        return (null === o ? "" : o.Key);
    }

    function KillToken() {
        if (localStorage.getItem(TOKEN_KEY)) {
            localStorage.removeItem(TOKEN_KEY);
            //$.fn.efw$broadcast.postCommand('deleteLocal', TOKEN_KEY, '');    // efw$broadcast obsolete
        }
    }

    // establish org-tracking cookie
    function SetOrgCookie() {
        var k = GetOrgKey(),
            r = GetRolesString();
        if (k.length > 0) {
            Cookies.set(ORG_COOKIE, k, ORG_SCOPE);
        } else {
            Cookies.set(ORG_COOKIE, "NONE", ORG_SCOPE);
        }
        Cookies.set(ROLES_COOKIE, r, ORG_SCOPE);
    }

    function GetOrgCookie() {
        return Cookies.get(ORG_COOKIE) || "";
    }

    //function KillOrgCookie() {
    //    Cookies.remove(ORG_COOKIE, ORG_SCOPE);
    //}

    // manipulate logon cookie, for calculators
    function SetLogonCookie(data) {
        var jwt = data[ACCESS_TOKEN_KEY] || "";
        //console.log('SetLogonCookie: ' + typeof (data[ACCESS_TOKEN_KEY]));
        try {
            Cookies.set(LOGON_COOKIE, (data[ACCESS_TOKEN_KEY] || "").toString(), LOGON_SCOPE);
        } catch {
            // let it go.
        }
    }

    function KillLogonCookie() {
        Cookies.remove(LOGON_COOKIE, LOGON_SCOPE);
    }

    //function TestLogonCookie() {
    //    var $c = Cookies.get(LOGON_COOKIE);
    //    return ("undefined" !== typeof($c) && null !== $c);
    //}

    function GetProSessionID() {
        return Cookies.get(PROSESSIONID_COOKIE) || "";
    }

    // set organization custom variable for Google Analytics
    function TrackOrgVariable() {
        var k = GetOrgCookie(),
            roles = GetRolesString();
        // Google analytics version. Set in beginHead_01
        if (window._gav && window._gav >= 4 && gtag) {
            // here is the deferred gtag config, establishing the wid_org custom user-scoped property.
            gtag('set', { 'wid_org': k, 'wid_roles': roles });
        } else {
            // older Google Analytics classic
            if (k.length > 0) {
                // being explicit about global scope of the _gaq
                window._gaq = window._gaq || [];    // in case it hasn't been initialized yet
                window._gaq.push(['_setCustomVar', cvSlot, ORG_COOKIE, k, cvScope]);
            }
        }
    }

    // if we're in Calculator context, save the session right now using web method
    function AutosaveSession() {
        var loc = window.location.toString();
        if (!loc.match(/\/planning\//))
            return;

        var strUrl = loc.replace(/([^?]*)\?(.*)/, '$1/AutosaveSession?$2');
        // ignore success and failure
        $.ajax({ cache: false, type: 'POST', url: strUrl, data: '{}', contentType: 'application/json; charset=utf-8', dataType: 'json' });
    }

    function IsLoggedIn() {
        return (null != GetToken());
    }

    function HasOrg() {
        return IsLoggedIn() && null !== GetOrg();
    }

    function AppendError($ul, e) {
        if (e.substring(0, 1) == "$")
            e = eval(e);
        $ul.append('<li tabindex="-1">' + e + '</li>'); // tabindex allows focus
        $ul.find('li').first().focus();
    }

    // form activation plugins
    $.fn.logon$registerform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $email = $this.find('[name=remail]');
            var $pass = $this.find('[name=rpass]');
            var $cpass = $this.find('[name=rcpass]');
            //var $cemail = $this.find('[name=rcemail]');
            var $org = $this.find('[name=rorgs]');
            var $submit = $this.find('[name=rsubmit]');
            var $status = $this.find('[name=rstatus]');

            // role-registration additional components
            //var $pro = $this.find('[name=rpro]');
            var $help = $this.find('.overlay-help');
            var $helpPanel = $this.find('.overlay-help-panel');
            var $back = $this.find('[name=rhelpdismiss]');
            var $main = $this.find('.overlay-main');
            var $roleReq = $this.find('[name=rpro]');
            var $role = $this.find('[name=rrole]');
            var $continuecheck = $this.find('input.requires-continue');
            var $continue = $this.find('[name=rcontinue]');
            var $continuePanel = $this.find('.overlay-dialog-continue');
            var $supervisorName = $this.find('#rrrsupervisor-name'),
                $supervisorEmail = $this.find('#rrrsupervisor-email'),
                $reason = $this.find('#rrrreason');

            var _manageContinueState = function () {
                var bChecked = $continuecheck.is(':checked');
                $submit.toggle(!bChecked);
                $continue.toggle(bChecked);
            }

            var _initialFormState = function () {
                $helpPanel.hide();
                $continuePanel.hide();
                $back.hide();
                $main.show();
                $supervisorName.prop('required', false);
                $supervisorEmail.prop('required', false);
                _manageContinueState();
            }

            // prep the form
            $form.logon$formvalid();

            // make sure form is in its initial state when opened
            $this.on('show.bs.modal', function () {
                if ($form.length > 0) {
                    $form.removeClass('was-validated');
                    $form[0].reset();
                }
                _initialFormState();
            });

            // activate the help system
            $help.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $main.hide();
                $submit.hide();
                $continue.hide();
                $helpPanel.show();
                $back.show();
            });

            $back.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                _initialFormState();
                $form.logon$formvalid('check');
            });

            // activate the continue/back system
            $continuecheck.on('click', _manageContinueState);

            $continue.on('click', function (e) {
                $status.empty();
                // don't submit the form.
                e.preventDefault();
                e.stopPropagation();

                // do validate page 1 of the form; we won't get another chance.
                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // valid! But - reset the visual validation state of the form now, as we move to page 2.
                $form.removeClass('was-validated');

                // require page-2 fields if needed
                var requiresSupervisor = $form.find('.requires-supervisor:checked').length > 0;
                $supervisorName.prop('required', requiresSupervisor);
                $supervisorEmail.prop('required', requiresSupervisor);

                // replace the main panel with continue panel
                $helpPanel.hide();
                $main.hide();
                $submit.show();
                $continue.hide();
                $continuePanel.show();
                $back.show();
            });

            // activate the org dropdown
            GetOrgs(opts, function (err, data) {
                $.each(data, function (i, d) {
                    $('select.org_list').append('<option value="' + d["Id"] + '">' + d["Display"] + '</option>');
                });
            });

            // password match is handled globally.

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "Register" });
            }

            // handle form submission
            $form.submit(function (event) {
                $status.empty();
                // prevent actual submission of the form
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                var $model = {
                    Email: $email.val(),
                    Password: $pass.val(),
                    ConfirmPassword: $cpass.val(),
                    OrganizationId: $org.val() || -1,
                    LanguageID: $.fn.efw$local.lang(),
                    SiteRegistered: opts.site || -1
                };
                if ($roleReq.is(':checked') && $role.val()) {
                    $model.Role = $role.val();
                    if ($reason.val())
                        $model.RequestReason = $reason.val();
                    if ($supervisorName.val())
                        $model.SupervisorName = $supervisorName.val();
                    if ($supervisorEmail.val())
                        $model.SupervisorEmail = $supervisorEmail.val();
                }

                // commit registration
                DoRegister(
                    $model,
                    // handlers. These will be sent on to DoLogon as well upon successful registration
                    $.extend({
                        dialogArea: $this,
                        statusArea: $status,
                        dispatchMap: { 409: function () { AppendError($status, "logon.register.error.taken"._local()); $status.find('a.dlgPop').efw$dialogPop(); }, 401: _logonFail, 403: _logonFail }
                    }, opts)
                );
            });
        });
    };

    $.fn.logon$logonform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $email = $this.find('[name=femail]');
            var $pass = $this.find('[name=fpass]');
            var $submit = $this.find('[name=fsubmit]');
            var $status = $this.find('[name=fstatus]')

            // prep the form
            $form.logon$formvalid();

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "Logon" });
            }

            // handle form submission
            $form.submit(function (event) {
                $status.empty();
                // prevent actual submission of the form
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // perform logon
                DoLogon(
                    // model
                    { username: $email.val(), password: $pass.val() },
                    // handlers
                    $.extend({
                        dialogArea: $this,
                        statusArea: $status,
                        successHandler: function (jqXHR, status, $handlers) {
                            $handlers.dialogArea.modal('hide');
                            // ...expects model { title, message, timeout }; will localize the strings
                            $.gevent.publish('efw.result', {
                                title: 'logon.success.title',
                                message: 'logon.success.message',
                                timeout: 5000
                            });
                        },
                        dispatchMap: { 400: _logonFail, 401: _logonFail, 403: _logonFail }
                    }, opts)
                );
                return;
            });
        });
    };

    $.fn.logon$changepassform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $opass = $this.find('[name=copass]');
            var $npass = $this.find('[name=cnpass]');
            var $cpass = $this.find('[name=ccpass]');
            var $submit = $this.find('[name=csubmit]');
            var $status = $this.find('[name=cstatus]');

            // prep the form
            $form.logon$formvalid();

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "ChangePassword" });
            }

            // bind submit action
            $submit.bind("click", function (event) {
                $status.empty();
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // commit
                DoChangePassword(
                    {
                        OldPassword: $opass.val(),
                        NewPassword: $npass.val(),
                        ConfirmPassword: $cpass.val(),
                        LanguageID: $.fn.efw$local.lang()
                    },
                    // handlers
                    $.extend({
                        statusArea: $status,
                        dialogArea: $this
                    }, opts)
                );
            });
        });
    };

    $.fn.logon$confirmemailform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            //var $form = $this.find('form');
            var $submit = $this.find('[name=cesubmit]');
            var $status = $this.find('[name=cestatus]');

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "SendConfirmEmail" });
            }

            // bind submit action
            $submit.bind("click", function (event) {
                $status.empty();
                event.preventDefault();
                event.stopPropagation();

                // send confirmation email
                DoConfEmail(
                    {
                        Email: GetUser(),
                        LanguageID: $.fn.efw$local.lang()
                    },
                    // handlers
                    $.extend({
                        statusArea: $status,
                        dialogArea: $this
                    }, opts)
                );
            });
            $.gevent.subscribe($this, 'ready-efw', function () {
                // handle explicit success or failure of email confirmation process
                var conf = getUrlParameter('mail-conf') || '';
                if ('true' === conf) {
                    // message success
                    // ...expects model { title, message, timeout }; will localize the strings
                    $.gevent.publish('efw.result', {
                        title: 'logon.success.title',
                        message: 'logon.mail.confirm.success.message',
                        timeout: 0 
                     });
                } else if ('false' === conf) {
                    // message failure
                    // 11/6/2020 Actually since there's no way to mitigate the problem, and since we don't care about the result, we'll message success
                    $.gevent.publish('efw.result', {
                        title: 'logon.success.title',
                        message: 'logon.mail.confirm.success.message',
                        timeout: 0
                    });
                }
            });
        });
    };

    $.fn.logon$profileform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $email = $this.find('[name=pemail]');
            //var $cemail = $this.find('[name=pcemail]');
            var $org = $this.find('[name=porgs]');
            var $submit = $this.find('[name=psubmit]');
            var $status = $this.find('[name=pstatus]');
            var $manageRoles = $this.find('.manage-roles-link');

            // prep the form
            $form.logon$formvalid();

            // disable manage-roles-link if appropriate
            $manageRoles.on('click', function (e) {
                if ($(this).hasClass('disabled')) {
                    e.preventDefault();
                    return false;
                } else {
                    return true;
                }
            });

            // populate form when loading
            $this.on('show.bs.modal', function () {
                if (IsLoggedIn()) {
                    $email.val(GetUser());
                    if (_GetDataBoolean("EmailConfirmed"))
                        $(this).find('[name=confemaillink]').hide();
                    var org = GetOrg();
                    $org.val(null === org ? -1 : org.Id);
                    if (opts.hideManageRoles) {
                        $manageRoles.closest('.cmdLink').hide();
                    } else {
                        GetMyPendingRoleRequests(opts, function (data) {
                            $manageRoles.toggleClass('disabled', (data && data.length > 0));
                        });
                    }
                }
            });

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "UpdateProfile" });
            }

            // bind submit action
            $submit.bind("click", function (event) {
                $status.empty();
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // commit registration
                DoProfile(
                    {
                        Email: $email.val(),
                        OrganizationId: $org.val() || -1,
                        LanguageID: $.fn.efw$local.lang()
                    },
                    // handlers
                    $.extend({
                        statusArea: $status,
                        dialogArea: $this
                    }, opts)
                );
            });
        });
    };

    $.fn.logon$forgotform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $email = $this.find('[name=ffemail]');
            var $submit = $this.find('[name=ffsubmit]');

            // prep the form
            $form.logon$formvalid();

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "ForgotPassword" });
            }

            // bind submit action
            $submit.bind("click", function (event) {
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // perform action
                DoForgotPassword(
                    {
                        email: $email.val(), resetpasswordurl: BASE_URL + "/?reset=true",
                        LanguageID: $.fn.efw$local.lang()
                    },
                    $.extend({
                        dialogArea: $this
                    }, opts)
                );
            });
        });
    };

    $.fn.logon$resetpassform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $email = $this.find('[name=rpemail]');
            var $npass = $this.find('[name=rpnpass]');
            var $cpass = $this.find('[name=rpcpass]');
            var $code = $this.find('[name=rpcode]');
            var $submit = $this.find('[name=rpsubmit]');
            var $status = $this.find('[name=rpstatus]');

            // prep the form
            $form.logon$formvalid();

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $submit.trackEvents({ category: "Clicks", action: "Logon", label: "ResetPassword" });
            }

            $this.on('show.bs.modal', function () {
                // stuff code value and email address. Set email late to foil password managers
                $email.val(getUrlParameterDecoded('email'));
                $code.val(getUrlParameterDecoded('code')); 
            });

            // bind submit action
            $submit.bind("click", function (event) {
                $status.empty();
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // reset the password
                DoResetPassword(
                    {
                        Email: $email.val(),
                        Password: $npass.val(),
                        ConfirmPassword: $cpass.val(),
                        Code: $code.val()
                    },
                    $.extend({
                        dialogArea: $this
                    }, opts)
                );
            });

            // launch this dialog if needed!
            //console.log('logon doreset: subscribe');
            $.gevent.subscribe($this, 'ready-efw', function () {
                //console.log('logon doreset: called');
                if ("true" === getUrlParameter('reset')) {
                    $this.modal('show');
                }
            });
        });
    };

    $.fn.logon$result = function (/* options */) {
        return this.each(function () {
            var $this = $(this);
            var $title = $this.find('#resulttitle');
            var $message = $this.find('#resultmessage');
            // clear dialog on close
            $this.on('hidden.bs.modal', function () {
                $title.text('');
                $message.html('');
            }).on('show.bs.modal', function () {
                // fix up dialog popup references in the text
                $(this).find('a.dlgPop').efw$dialogPop();
            });
            // bind to global event
            // expects model: { title, message, timeout }
            $.gevent.subscribe($(this), 'efw.result', function (e, m) {
                var $model = $.extend({ title: "", message: "", timeout: 0 }, m)
                //console.log('$model: ' + JSON.stringify($model));
                $title.text($model.title._local());
                $message.html($model.message._local());
                if ($model.timeout) {
                    setTimeout(function () {
                        $this.modal('hide');
                        $title.text("");
                        $message.html("");
                    }, $model.timeout);
                }
                $this.modal('show');
            });
        });
    };

    // in the Logon or Register dialog, we'll display the intent of the link that launched us
    $.fn.logon$orregister = function (/* options */) {
        //var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $title = $this.find('h5');

            $this.on('show.bs.open', function () {
                var $opener = $(this).data('opener');
                if ($opener) {
                    var $intent = $($opener).attr('intent');
                    if ($intent && typeof($intent) == "string" && $intent.length > 0)
                    {
                        $title.text($intent._local());
                    }
                }
            });
        });
    };

    $.fn.logon$favoriteui = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $add = $this.find('.add');
            var $remove = $this.find('.remove');
            var $intent = $this.parent().find('.saveFavoriteIntent');
            var $url = $add.attr('rel') || window.location.pathname;

            var $update = function () {
                var $fav = MatchFavorite($this.attr('rel-type'), $this.attr('rel-name'), $url);
                if (null !== $fav) {
                    $remove.prop('rel', $fav.id);
                    $add.hide();
                    $remove.show();
                } else {
                    $add.show();
                    $remove.hide();
                }
            }

            $add.on('click', function (e) {
                e.preventDefault();
                PostFavorite({ SiteId: opts.site, URL: $url, Title: $add.attr('rel-title') || $('head title').text() }, opts);
            });

            $remove.on('click', function (e) {
                e.preventDefault();
                DeleteFavorite($remove.prop('rel'), opts);
            });

            $intent.on('click', function (e) {
                // intends to save favorite, if we would just log on
                e.preventDefault();
                $(this).prop('intentclicked', true);
            });

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $add.trackEvents({ category: "Clicks", action: "AddFavorite", label: $url });
                $remove.trackEvents({ category: "Clicks", action: "RemoveFavorite", label: $url });
            }

            $.gevent.subscribe($this, 'efw.favoritesUpdate', $update);

            // follow through on user intent, if any
            $.gevent.subscribe($intent, 'efw.logon', function () {
                var $target = $(this);
                if ($target.prop('intentclicked'))
                {
                    PostFavorite({ SiteId: opts.site, URL: $url, Title: $target.attr('rel-title') || $('head title').text() }, opts);
                    $target.prop('intentclicked', false);
                }
            });

            $.gevent.subscribe($this, 'efw.logon', function () {
                GetFavorites(opts);
            })
        })
    }

    $.fn.logon$logarea = function (/* options */) {

        return this.each(function () {
            $('[name=logout]').bind('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                DoLogoff();
                $.gevent.publish('efw.logoutmanual');
            });

            // subscribe all loggedOut/loggedIn areas
            $.gevent.subscribe($('body'), 'efw.logout', function (e) {
                e.stopPropagation();
                $(this).removeClass('is-logged-on');
                $(this).addClass('is-logged-out');
            });
            $.gevent.subscribe($('body'), 'efw.logon', function (e) {
                e.stopPropagation();
                $(this).addClass('is-logged-on');
                $(this).removeClass('is-logged-out');
            });
            $.gevent.subscribe($(".loggedOut"), 'efw.logon', function (e) {
                e.stopPropagation();
                $(this).hide();
            });
            $.gevent.subscribe($(".loggedOut"), 'efw.logout', function (e) {
                e.stopPropagation();
                $(this).show();
            });
            $.gevent.subscribe($(".loggedIn"), 'efw.logon', function (e) {
                e.stopPropagation();
                $(this).show();
            });
            $.gevent.subscribe($(".loggedIn"), 'efw.logout', function (e) {
                e.stopPropagation();
                $(this).hide();
            });
            $.gevent.subscribe($(".hasOrg"), 'efw.logon', function (e) {
                e.stopPropagation();
                if (HasOrg())
                    $(this).show();
            });
            $.gevent.subscribe($(".hasOrg"), 'efw.logout', function (e) {
                e.stopPropagation();
                $(this).hide();
            });
            $.gevent.subscribe($("[name=logname]"), 'efw.logon', function (e) {
                e.stopPropagation();
                $(this).text(GetUser());
            });
            $.gevent.subscribe($("input.logname"), 'efw.logon', function () {
                var text = GetUser();
                $(this).val(text);
                // survive form resets.
                $(this).each(function () {
                    this.defaultValue = text;
                });
            });
            $.gevent.subscribe($("input.logname"), 'efw.logout', function () {
                $(this).val('');
                // clear form resets.
                $(this).each(function () {
                    this.defaultValue = '';
                });
            });
            $.gevent.subscribe($(".styleButtonFeedback a"), 'efw.logon', function () {
                $(this).prop('href', $(this).attr('href') + "&f=" + encodeURIComponent(GetUser()));
            });
            $.gevent.subscribe($("span.styleSaveSessionPrompt"), 'efw.logon', function () {
                $(this).text("savedsession.sessionsavedprompt"._local());
            });
            $.gevent.subscribe($("[class^='isInRole_']"), 'efw.logout', function () {
                $(this).hide();
            });
            $.gevent.subscribe($("[class^='isInRole_']"), 'efw.logon', function () {
                var $elem = $(this);
                $.each(GetRoles(), function (i, r) {
                    if ($elem.hasClass('isInRole_' + r))
                        $elem.show();
                });
            });
        })
    };

    // build table of organizations
    $.fn.logon$orgsarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            $this.append('<table class="orgTable"><thead><tr><th>ID</th><th>Key</th><th>Display</th></tr></thead><tbody></tbody></table>');
            $this.on('efw.orgs', function () {
                $this.find('tbody').empty();
                GetOrgs(opts, function (err, data) {
                    $.each(data, function (i, d) {
                        $this.find('tbody').append('<tr><td>' + d["Id"] + '</td><td>' + d["Key"] + '</td><td>' + d["Display"] + '</td></tr>');
                    });
                });
            }).trigger('efw.orgs');
        });
    };

    $.fn.logon$userrolesarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        if (!IsLoggedIn())
            return;

        return this.each(function () {
            var $this = $(this);
            $this.append('<table class="orgTable"><thead><tr><th>User</th><th>Role</th></tr></thead><tbody></tbody></table>');
            $this.on('efw.userrole', function () {
                $this.find('tbody').empty();
                GetUserRoles(opts, '', function (i, d) {
                    $this.find('tbody').append('<tr><td>' + d["User"] + '</td><td>' + d["Role"] + '</td></tr>');
                });
            }).trigger('efw.userrole');
        });
    };

    // Extended user-role functionality
    $.fn.logon$userroles2area = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.createuserrolesarea');
            });

            $this.on('efw.createuserrolesarea', function () {
                var $roles = [];
                $this.empty();

                if (!IsInRole('admin')) {
                    $this.append('<div><em>You need the "admin" role for this function.</em></div>');
                    return;
                }

                $this.append('<div class="user-search"><label for="user">User search:</label><input id="user" type="text" placeholder="Email" /><button id="userFind">Find</button></div>');
                $this.append('<table class="user-role-table"><thead></thead><tbody></tbody></table>');
                $this.append('<div class="user-role-submit"><button id="user-role-submit" disabled>Submit</button></div>');
                var $head = $this.find('thead'),
                    $body = $this.find('tbody'),
                    $find = $this.find('#userFind'),
                    $user = $this.find('#user'),
                    $submit = $this.find('#user-role-submit');

                $body.empty();
                $head.empty();
                $submit.prop('disbled', true);
                var $ctlRow = $('<tr class="role-head" style="vertical-align: top; font-size: 0.8em;"></tr>');
                var $headRow = $('<tr class="role-head" style="vertical-align: top"></tr>');
                $ctlRow.append('<th></th>');
                $headRow.append('<th>User</th>');
                GetAllRoles(opts, function (data) {
                    $roles = data;
                    $.each($roles, function (i, v) {
                        var id = 'selectrole_' + _identifier(v.Name);
                        $ctlRow.append('<th class="role-column"><button class="find-role" data-role="' + v.Name + '">Find&nbsp;All&nbsp;&darr;</button></th>');
                        $headRow.append('<th class="role-column nowrap"><div class="form-check"><input class="form-check-input" type="checkbox" name="' + _identifier(v.Name) + '" id="' + id + '" data-role="' + v.Name + '"><label class="form-check-label normalwrap lh-14" for="' + id + '" title="' + (v.Description || v.Name) + '">' + (v.Title || v.Name) + '</label></div></th>');
                    });
                    //$head.append($ctlRow);
                    $head.append($headRow);
                    $user.focus(function () {
                        if ($(this).val() == $(this).prop('placeholder'))
                            $(this).val('');
                    });
                    //$user.autocomplete({
                    //    autoFocus: true,
                    //    source: function (request, response) {
                    //        return GetFilteredAccounts(opts, request.term, response);
                    //    }
                    //});
                    $user.autoComplete({
                        minLength: 1,
                        resolver: 'custom',
                        events: {
                            search: function (qry, callback) {
                                GetFilteredAccounts(opts, qry, callback);
                            }
                        }
                    });
                    $this.off('change', '.role-body :checkbox').on('change', '.role-body :checkbox', function () {
                        $submit.prop('disabled', false);
                    });
                    $this.off('change', '.role-head :checkbox').on('change', '.role-head :checkbox', function () {
                        $this.find('.role-body :checkbox[data-role=' + $(this).data('role') + ']').prop('checked', $(this).prop('checked'));
                        $submit.prop('disabled', false);
                    });
                    var $findRole = $this.find('.find-role');
                    var buildRoleTable = function (i, u) {
                        var $row = $('<tr class="role-body"></tr>');
                        $row.append('<td>' + u.User + '</td>');
                        $.each($roles, function (i, r) {
                            var s = '<td class="role-column">';
                            s += '<input type="checkbox" data-user="' + u.User + '" data-role="' + r.Name + '" class="user-role ' + _identifier(r.Name) + '" name="' + _identifier(u.User + '_' + r.Name) + '"';
                            if (u.Roles.indexOf(r.Name) > -1)
                                s += ' checked="checked"';
                            s += ' />';
                            s += '</td>';
                            $row.append(s);
                        });
                        $body.append($row);
                    };
                    $findRole.off('click').on('click', function (e) {
                        e.preventDefault();
                        $body.empty();
                        GetUserRolesByRole(opts, $(e.target).data('role'), buildRoleTable);
                    });
                    $find.off('click').on('click', function (e) {
                        e.preventDefault();
                        $body.empty();
                        GetUserRoles(opts, $user.val(), buildRoleTable);
                    });
                    $submit.off('click').on('click', function (e) {
                        e.preventDefault();
                        // serialize the checkboxes
                        // easiest to track as associative array
                        var dict = {}; // { username = [r0, r1, r2] }
                        $this.find('.user-role:checkbox').each(function (i, c) {
                            var user = $(c).data('user');
                            var role = $(c).data('role');
                            dict[user] = dict[user] || [];
                            if (c.checked) {
                                dict[user].push(role);
                            }
                        });
                        // marshal model for api
                        var model = []; // [{ User = username, Roles = [ r0, r1, r2 ] }]
                        $.each(dict, function (u, rl) {
                            model.push({ User: u, Roles: rl });
                        });
                        //console.log('Output: ');
                        //console.dir(model);

                        SetUserRoles(opts, model, function (d) {
                            if (!d)
                                console.log('SetUserRoles: Error');
                            $body.empty();
                            $user.empty();
                            $submit.prop('disabled', true);
                        });
                    });
                });
            });
        });
    };

    // Manage role requests
    $.fn.logon$rolerequestsarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.createrolerequestsarea');
            });

            $this.on('efw.createrolerequestsarea', function () {
                $this.empty();

                if (!IsInRole('admin') && !IsInRole('provider-approver')) {
                    $this.append('<div><em>You need the "admin" or "provider-approver" role for this function.</em></div>');
                    return;
                }

                var $roleRequests = [];
                $this.append('<a class="roles-refresh roles-float-right" href="javascript:void(0);">Refresh</a>')
                $this.append('<h3>Pending Requests</h3>');
                $this.append('<table class="user-role-table"><thead></thead><tbody></tbody></table>');
                $this.append('<div class="role-requests-reason-wrap" style="display: none;"><div>Reason:</div><textarea class="role-requests-reason reason-slot"></textarea><div class="request-standard-reasons"></div></div>');
                $this.append('<div class="role-requests-submit"><button id="role-requests-approve" disabled>Approve All Selected</button><button class="role-requests-deny" id="role-requests-deny" disabled>Deny All Selected</button></div>');
                var $head = $this.find('thead'),
                    $body = $this.find('tbody'),
                    $reasonWrap = $this.find('.role-requests-reason-wrap'),
                    $reason = $this.find('.role-requests-reason'),
                    $approve = $this.find('#role-requests-approve'),
                    $deny = $this.find('#role-requests-deny'),
                    $submits = $this.find('button');
                $this.off('click', '.roles-refresh').on('click', '.roles-refresh', function (e) {
                    e.preventDefault();
                    $this.trigger('efw.getrolerequests');
                    return false;
                });
                $this.off('change', '.select-all').on('change', '.select-all', function () {
                    $this.find('.select-request').prop('checked', $(this).prop('checked')).change();
                });
                $this.off('change', '.select-request').on('change', '.select-request', function () {
                    var anySelected = ($this.find('.select-request:checked').length) > 0;
                    $submits.prop('disabled', !anySelected);
                    $reasonWrap.toggle(anySelected);
                });
                $approve.off('click').on('click', function (e) {
                    e.preventDefault();
                    // serialize the checkboxes
                    var requests = [];
                    $this.find('.select-request:checkbox').each(function (i, c) {
                        if (c.checked) {
                            requests.push({ RequestId: $(c).data('id') });
                        }
                    });
                    if (requests.length > 0) {
                        ApproveRoleRequests(opts, requests, function (d) {
                            if (!d)
                                console.log('ApproveRoleRequests: Error');
                            $this.trigger('efw.getrolerequests');
                        });
                    }
                    return false;
                });
                $deny.off('click').on('click', function (e) {
                    e.preventDefault();
                    // serialize the checkboxes
                    var requests = [];
                    $this.find('.select-request:checkbox').each(function (i, c) {
                        if (c.checked) {
                            requests.push({ RequestId: $(c).data('id'), DeniedReason: $reason.val() });
                        }
                    });
                    if (requests.length > 0) {
                        DenyRoleRequests(opts, requests, function (d) {
                            if (!d)
                                console.log('DenyRoleRequests: Error');
                            $this.trigger('efw.getrolerequests');
                        });
                        return false;
                    }
                });
                $this.off('click', 'button.role-command-button').on('click', 'button.role-command-button', function (e) {
                    e.preventDefault();
                    //console.log('Button click: ' + $(this).data('command') + ' ' + $(this).data('id'));
                    return false;
                });

                $this.off('efw.getrolerequests').on('efw.getrolerequests', function () {
                    $body.empty();
                    $head.empty();
                    $reason.val('');
                    $reasonWrap.hide();
                    $submits.prop('disabled', true);
                    $head.append('<tr class="role-head" style="vertical-align: top"><th><div class="form-check"><input class="form-check-input select-all" type="checkbox" id="request-select-all" /><label class="form-check-label" for="request-select-all">Select</label></div></th><th>Role</th><th>Requester</th><th>Reason</th><th>Supervisor</th><th>Date</th><th></th></tr>');
                    GetAllRoles(opts, function (droles) {
                        var $rolesMap = {};
                        $.each(droles, function (i, d) {
                            $rolesMap[d.Name] = d;
                        });
                        GetPendingRoleRequests(opts, function (data) {
                            if (null == data) {
                                $this.append('<h3>You do not have permission to review role requests.</h3>');
                                return;
                            }
                            $roleRequests = data;
                            $roleRequests.sort(function (a, b) {
                                return a.Created > b.Created;
                            });
                            $.each($roleRequests, function (i, v) {
                                var id = 'request_' + v.RequestId;
                                var $row = $('<tr></tr>');
                                $row.append('<td><div class="form-check"><input class="form-check-input select-request" type="checkbox" name="' + v.RequestId + '" id="sel_' + id + '" data-id="' + v.RequestId + '"><label class="form-check=input" for="sel_' + id + '">#' + v.RequestId + '</label></div></td>')
                                    .append('<td>' + ($rolesMap[v.RoleName] ? $rolesMap[v.RoleName].Title : v.RoleName) + '</td>')
                                    .append('<td>' + v.UserEmail + '</td>')
                                    .append('<td>' + v.RequestReason + '</td>')
                                    .append('<td>' + (v.SupervisorEmail || v.SupervisorName ? v.SupervisorName + '<br /><a href="mailto:' + v.SupervisorEmail + '">' + v.SupervisorEmail + '</a>' : '') + '</td>')
                                    .append('<td>' + (new Date(v.Created)).toLocaleString() + '</td>')
                                    .append('<td><div class="reason-wrap request-primed" style="display: none;"><label class="required" for="reason_' + id + '">Reason:&nbsp;</label><input id="reason_' + id + '" class="role-deny-reason-short reason-slot" type="text" /><button class="role-command-button role-deny" data-command="deny" data-id="' + v.RequestId + '" disabled>Deny</button><div class="request-standard-reasons"></div></div><div class="request-unprimed"><button class="role-command-button role-approve" data-command="approve" data-id="' + v.RequestId + '">Approve</button><button class="role-command-button role-deny-prime" data-command="deny-prime">Deny...</button></div></td>');
                                $body.append($row);
                            });
                            if (0 == $roleRequests.length) {
                                var $row = $('<tr></tr>');
                                $row.append('<td colspan="7"><em>No pending role requests.</em></td>');
                                $body.append($row);
                                $head.find(':checkbox').prop('disabled', true);
                            } else {
                                // populate standard reasons
                                $.ajax({
                                    type: "GET",
                                    url: "/js/jquery/efw.rolerequest.reasons.json",
                                    dataType: "json",
                                    success: function (data) {
                                        if (null == data || undefined === data)
                                            return;

                                        if (data.reasons) {
                                            var $reasonSlot = $this.find('.reason-slot'),
                                                $standardReasons = $this.find('.request-standard-reasons');

                                            $standardReasons.empty();
                                            var $reasonPicker = $('<select class="rolerequest-reasons-picker"><option value="" selected>Select a reason (optional):</option></select>');

                                            $.each(data.reasons, function (i, reason) {
                                                $reasonPicker.append($('<option value="' + reason.text + '">' + reason.prompt + '</option>'));
                                            });

                                            $reasonPicker.off('change').on('change', function (e) {
                                                e.preventDefault();
                                                $('.rolerequest-reasons-picker').val($(this).val());    // make all the same.
                                                $reasonSlot.val($(this).val());
                                                $body.find('input.role-deny-reason-short').trigger('input');
                                            });

                                            $standardReasons.each(function () {
                                                $(this).append($reasonPicker.clone(true)); // separate copy in each context, with data and events.
                                            });
                                        }
                                    }
                                    // fail silently.
                                });

                                // enable deny button when there's input
                                $body.find('input.role-deny-reason-short').on('input', function () {
                                    var $i = $(this),
                                        $denyOne = $i.parent().find('.role-command-button.role-deny');

                                    $denyOne.prop('disabled', !$i.val());                                    
                                });
                                // click handler for individual approve/deny buttons
                                $body.find('button.role-command-button').on('click', function (e) {
                                    e.preventDefault();
                                    var $b = $(this),
                                        $cmd = $b.attr('data-command'),
                                        //$id = $b.attr('data-id'),
                                        $reason = $b.parent().find('.role-deny-reason-short');
                                    if ('approve' == $cmd) {
                                        ApproveRoleRequest(opts, { RequestId: $b.data('id') }, function (d) {
                                            if (!d)
                                                console.log('ApproveRoleRequest: Error');
                                            $this.trigger('efw.getrolerequests');
                                        });
                                    } else if ('deny' == $cmd) {
                                        DenyRoleRequest(opts, { RequestId: $b.data('id'), DeniedReason: $reason.val() }, function (d) {
                                            if (!d)
                                                console.log('DenyRoleRequest: Error');
                                            $this.trigger('efw.getrolerequests');
                                        });
                                    } else if ('deny-prime' == $cmd) {
                                        $b.closest('td').find('.request-primed').show();
                                        $b.closest('td').find('.request-unprimed').hide();
                                    }

                                    return false;
                                })
                            }
                        });
                    });
                });
                $this.trigger('efw.getrolerequests');
            });
        });
    };

    // Provisional account creation
    $.fn.logon$registerprovisionalarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.createregisterprovisionalarea');
            });

            $this.on('efw.createregisterprovisionalarea', function () {
                $this.empty();

                if (!IsInRole('admin')) {
                    $this.append('<div><em>You need the "admin" role for this function.</em></div>');
                    return;
                }

                var $form = $('<form class="formarea" action="#"></form>'),
                    $formwrap = $('<div class="rprwrap"></div>'),
                    $formleft = $('<div class="rprleft"></div>'),
                    $formright = $('<div class="rprright"></div>');

                $formleft.append('<div class="form-group"><label for="rpremail" class="required">New Account Email:</label><input type="email" required="required" id="rpremail" class="rpr-input form-control" /></div>');
                $formleft.append('<div class="form-group"><label for="rprorg">Organization:</label><select class="rpr-org form-control" id="rprorg"><option value="-1">None of these</option></select></div>');
                GetOrgs(opts, function (err, data) {
                    $.each(data, function (i, d) {
                        $formleft.find('select#rprorg').append('<option value="' + d["Id"] + '">' + d["Display"] + '</option>');
                    });
                });
                $formleft.append('<div class="form-group"><label for="rprmemail">Your Email:</label><input type="email" id="rprmemail" class="rpr-input form-control" disabled required="required" value="' + GetUser() + '" /></div>');
                $formleft.append('<div class="form-group"><label for="rprmname" class="required">Your Name:</label><input type="text" id="rprmname" class="rpr-input form-control" required="required" /></div>');
                $formleft.append('<input type="hidden" id="rprrpu" value="' + window.location.protocol + '//' + window.location.host + '?_reset=true" />');
                $formleft.append('<button id="rprsubmit btn btn-primary">Submit</button>');
                $formleft.append('<div><ul id="rprstatus" class="status"></ul></div>');

                $formright.append('<h3>Roles for this account:</h3>');

                GetAllRoles(opts, function (droles) {
                    $.each(droles, function (i, d) {
                        $formright.append('<div class="form-check"><input class="form-check-input" type="checkbox" id="rpr_' + d.Name + '" data-val="' + d.Name + '">&nbsp;<label class="form-check-label" for="rpr_' + d.Name + '">' + (d.Title || d.Name) + '</label></div>');
                    });
                });

                $formwrap.append($formleft);
                $formwrap.append($formright);
                $form.append($formwrap);
                $this.append($form);

                var $status = $form.find('#rprstatus');

                // prep the form
                $form.logon$formvalid();

                // handle form submission
                $form.off('submit').on('submit', function (event) {
                    $status.empty();
                    // prevent actual submission of the form
                    event.preventDefault();
                    event.stopPropagation();

                    var valid = $form.logon$formvalid('check');

                    if (!valid)
                        return;

                    // commit registration
                    DoRegisterProvisional(
                        {
                            Email: $form.find('#rpremail').val(),
                            OrganizationId: $form.find('#rprorg').val() || -1,
                            ManagerEmail: $form.find('#rprmemail').val(),
                            ManagerName: $form.find('#rprmname').val(),
                            ResetPasswordUrl: $form.find('#rprrpu').val(),
                            LanguageID: $.fn.efw$local.lang(),
                            SiteRegistered: opts.site || -1
                        },
                        // handlers. These will be sent on to DoLogon as well upon successful registration
                        $.extend({
                            dialogArea: $this,
                            statusArea: $status,
                            successHandler: function () {
                                //console.log('Success...');
                                AppendError($status, "general.success.title"._local());

                                var roles = [];
                                $form.find(':checkbox:checked').each(function () {
                                    roles.push($(this).attr('data-val'));
                                });
                                var $model = [{ User: $form.find('#rpremail').val(), Roles: roles }];
                                SetUserRoles(opts, $model, function () {
                                    AppendError($status, "Roles assigned: " + roles.join(', '));
                                    $form.find('#rpremail').val('');
                                    $form.find('#rprorg').val('-1');
                                    $form.find(':checkbox').prop('checked', false);
                                });
                            },
                            dispatchMap: { 409: function () { AppendError($status, "logon.update.error.taken"._local()); }, 401: _logonFail, 403: _logonFail }
                        }, opts)
                    );
                });
            });
        });
    };

    // Role-communications
    $.fn.logon$rolescommsarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);
        var role = opts.role;

        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.createrolescommsarea');
            });

            $this.on('efw.createrolescommsarea', function () {
                // avoid multiple initializations
                if ($.trim($this.html()) !== '')
                    return;

                $this.empty();

                if (!(opts.allowInterface || IsInRole('admin'))) {
                    $this.append('<div><em>You need the "admin" role for this function.</em></div>');
                    return;
                }

                var $roles = [],
                    labelPrefix = role ? role + '_' : '',
                    rolesID = `${labelPrefix}rcaRoles`,
                    commsID = `${labelPrefix}rcaComms`,
                    copyID = `${labelPrefix}rcaCopy`,
                    copyAreaID = `${labelPrefix}rcaCopyArea`,
                    copyButtonID = `${labelPrefix}rcaCopyButton`,
                    viewID = `${labelPrefix}rcaView`,
                    emailID = `${labelPrefix}rcaEmail`,
                    viewListID = `${labelPrefix}rcaViewList`;

                $this.append(`<div class="form-group"${role ? ' style="display: none;"' : ''}><label for="${rolesID}">Role:&nbsp;</label><select id="${rolesID}"><option value="">Choose a role...</option></select></div>`);
                $this.append(`<div id="${commsID}"><ul class="nav nav-tabs" role="tablist"><li class="nav-item"><a class="nav-link active" data-toggle="tab" role="tab" href="#${viewID}">View List</a></li><li class="nav-item"><a class="nav-link" data-toggle="tab" role="tab" href="#${copyID}">Copy List</a></li><li class="nav-item"><a class="nav-link" data-toggle="tab" role="tab" href="#${emailID}">Email to List</a></li></ul><div class="tab-content"><div class="tab-pane fade show active" id="${viewID}"></div><div class="tab-pane fade" id="${copyID}"></div><div class="tab-pane fade" id="${emailID}"></div></div></div>`);
                //$('#rcaComms').tabs();

                var $select = $this.find('select'),
                    $copy = $this.find(`#${copyID}`),
                    $view = $this.find(`#${viewID}`),
                    $email = $this.find(`#${emailID}`);

                $this.on('efw.selectrolescomms', function () {
                    var $role = $select.val();
                    $view.empty();
                    $view.append('<h4>View "' + $role + '" User List</h4>');
                    $view.append(`<ul id="${viewListID}"></ul>`);
                    $copy.empty();
                    $copy.append('<h4>Copy "' + $role + '" Email List</h4>');
                    $email.empty();
                    $email.append('<h4>Send Email to "' + $role + '" User List</h4>');
                    GetAccountsByRole($.extend(opts, { role: $select.val() }), function (data) {
                        if (!data || 0 == data.length) {
                            $copy.append('<div><em>No accounts have this role.</em></div>');
                            $view.append('<div><em>No accounts have this role.</em></div>');
                            $email.append('<div><em>No accounts have this role.</em></div>');
                            return;
                        }
                        // view page
                        var $viewList = $view.find(`#${viewListID}`);
                        $.each(data, function (i, u) {
                            $viewList.append('<li>' + u + '</li>');
                        });

                        // copy page
                        var $textTarget = $copy.append(`<div class="form-group"><textarea id="${copyAreaID}" class="form-control rca-copy-area"></textarea></div>`).find(`#${copyAreaID}`);
                        $textTarget.val(data.join(', '));
                        $copy.append(`<div class="form-group user-role-submit"><button class="btn btn-primary" id="${copyButtonID}">Copy</button></div>`);
                        var $copyButton = $copy.find(`#${copyButtonID}`);
                        $copyButton.on('click', function (e) {
                            e.preventDefault();
                            $textTarget.trigger('select');
                            document.execCommand("copy");
                            alert('The text has been copied to the clipboard.');
                        });

                        // email form
                        var $form = $('<form class="formarea" action="#"></form>');
                        var eemailID = `${labelPrefix}rcamemail`,
                            enameID = `${labelPrefix}rcamname`,
                            esubjID = `${labelPrefix}rcaesubj`,
                            ebodyID = `${labelPrefix}rcaebody`;
                        $form.append(`<div class="form-group">To: ${data.join(', ')}</div>`);
                        $form.append(`<div class="form-group"><label for="${eemailID}">Your Email:</label><input type="text" id="${eemailID}" name="memail" class="rpr-input" disabled value="${GetUser()}" /></div>`);
                        $form.append(`<div class="form-group"><label for="${enameID}" class="required">Your Name:</label><input type="text" id="${enameID}" name="mname" class="rpr-input form-control" required="required" /></div>`);
                        $form.append(`<div class="form-group"><label for="${esubjID}" class="required">Subject:</label><input type="text" id="${esubjID}" name="subj" class="rpr-input form-control" required="required" /></div>`);
                        $form.append(`<div class="form-group"><label for="${ebodyID}" class="required">Message:</label><textarea id="${ebodyID}" name="body" class="rca-email-body form-control" required="required" /></div>`);
                        $form.append('<div class="form-group"><button class="btn btn-primary">Submit</button></div>');
                        $form.append('<div><ul class="status"></ul></div>');
                        $email.append($form);
                        var $status = $form.find('.status');

                        // prep the form
                        $form.logon$formvalid();

                        // handle form submission
                        $form.on('submit', function (event) {
                            $status.empty();
                            // prevent actual submission of the form
                            event.preventDefault();
                            event.stopPropagation();

                            var valid = $form.logon$formvalid('check');

                            if (!valid)
                                return;

                            // send email
                            DoSendRoleEmail(
                                {
                                    RoleName: $role,
                                    ManagerEmail: $form.find('[name=memail]').val(),
                                    ManagerName: $form.find('[name=mname]').val(),
                                    Subject: $form.find('[name=subj]').val(),
                                    Body: $form.find('[name=body]').val()
                                },
                                // handlers
                                $.extend({
                                    dialogArea: $this,
                                    statusArea: $status,
                                    successHandler: function () {
                                        //console.log('Success...');
                                        $form.find('[name=subj]').val('');
                                        $form.find('[name=body]').val('');
                                        AppendError($status, "general.success.title"._local());
                                        $form.removeClass('was-validated');
                                    }
                                }, opts)
                            );
                        });
                    });
                });

                $select.off('change').on('change', function () {
                    if ($select.val())
                        $this.trigger('efw.selectrolescomms');
                });

                // did options specify a particular role?
                if (opts.role) {
                    $select.append(`<option value="${opts.role}" selected="true" />`);
                    $select.trigger('change')
                } else {
                    GetAllRoles(opts, function (data) {
                        $roles = data;
                        $.each($roles, function (i, v) {
                            //var id = 'selectrole_' + _identifier(v.Name);
                            $select.append('<option value="' + v.Name + '">' + (v.Title || v.Name) + '</option>');
                        });
                    });
                }
            });
        });
    };

    // Managed Roles
    $.fn.logon$managedrolesarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options),
            localRoleCreator = `{opts.stateCode.toLowerCase()}-role-creator`;

        return this.each(function () {
            var $this = $(this);
            let yourName = '';

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.managedrolesarea');
            });

            $this.on('efw.managedrolesarea', function () {
                $this.empty();

                if (!IsInRole('admin') && !IsInRole(localRoleCreator)) {
                    $this.append(`<div><em>You need the "admin" or "{localRoleCreator}" role for this function.</em></div>`);
                    return;
                }

                $this.append(`<p><a href="/_rolemanager.htm" target="_rolemanager" class="offsite-link">Go to Role Manager Control Panel</a></p>`);

                // List managed roles.
                var $roleTable = $('<table class="user-role-table"><thead><tr><th>Role</th><th>Title</th><th>Description</th><th>Managed By</th></tr></thead><tbody></tbody></table>'),
                    $body = $roleTable.find('tbody');

                $this.append($roleTable);

                GetManagedRoles(opts, function (i, r) {
                        //console.log('Row: ' + JSON.stringify(r));
                        $body.append(`<tr><td>${r.Name}</td><td>${r.Title}</td><td>${r.Description}</td><td>${r.Managers?.length ? `<ul>${r.Managers.reduce((acc, m) => `${acc}<li><a href="mailto:${m}">${m}</a></li>`, '')}</ul>` : ''}</td></tr>`);
                    },
                    function () {
                        $this.append("<div>No managed roles.</div>");
                        $roleTable.remove();
                    }
                );

                // Create a managed role.
                $this.append(`<h3>Create Managed Role</h3>`);
                var $roleForm = $(`<form action="#"></form>`);
                $roleForm.append(`<div class="form-group"><label for="cmrMEmail">Your Email:</label><input type="email" id="cmrMEmail" name="ManagerEmail" class="rpr-input form-control" disabled required="required" value="${GetUser()}" /></div>`);
                $roleForm.append(`<div class="form-group"><label for="cmrMName" class="required">Your Name:</label><input type="text" id="cmrMName" name="ManagerName" class="rpr-input form-control" required="required" value="${yourName}" /></div>`);
                $roleForm.append(`<div class="form-group"><label for="cmrTitle">Role Title:</label><input type="text" name="Title" class="form-control" id="cmrTitle" required /></div>`);
                $roleForm.append(`<div class="form-group"><label for="cmrDescription">Role Description:</label><textarea rows="3" class="form-control" name="Description" id="cmrDescription" required /></div>`);
                $roleForm.append(`<div class="form-group"><label for="cmrRMEmail">Email address to manage this role:</label><input type="email" name="RoleManagerEmail" class="form-control" id="cmrRMEmail" required /></div>`);
                $roleForm.append('<div class="form-group"><label for="cmrOrg">Organization (if creating new account):</label><select class="rpr-org form-control" id="cmrOrg" name="OrganizationId"><option value="-1">None of these</option></select></div>');
                GetOrgs(opts, function (err, data) {
                    $.each(data, function (i, d) {
                        $roleForm.find('select#cmrOrg').append(`<option value="${d["Id"]}">${d["Display"]}</option>`);
                    });
                });
                $roleForm.append(`<input type="hidden" id="cmrReset" name="ResetPasswordUrl" value="${window.location.protocol}//${window.location.host}?_reset=true" />`);
                $roleForm.append(`<input type="hidden" name="StateCode" value="${opts.stateCode}" id="cmrState" /></div>`);
                $roleForm.append($(`<button id="cmrSubmit" type="submit" class="btn btn-primary">Submit</button>`));

                $this.append($roleForm);

                $roleForm.logon$formvalid();

                $roleForm.on('submit', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!$roleForm.logon$formvalid('check'))
                        return;

                    var model = {};
                    // don't use jQuery.serializeArray because it ignores disabled fields
                    $roleForm.find('input, textarea, select').each(function (i, v) {
                        model[$(v).attr('name')] = $(v).val();
                    });

                    yourName = model.ManagerName || ''; // retain for repaint

                    model.LanguageID = $.fn.efw$local.lang();

                    DoCreateManagedRole(model,
                        $.extend(
                            {
                                successHandler: function (jqXHR, status, $handlers) {
                                    console.info(`CreateManagedRole: Status ${status}`);
                                    // extract model from response
                                    var model = jqXHR.responseJSON;
                                    // interpolate model fields into response string
                                    // pop up dialog with response
                                    $.gevent.publish('efw.result', {
                                        title: 'logon.success.title',
                                        message: 'logon.create.managedrole.success'._local(model),
                                        timeout: 5000
                                    });
                                    // refresh whole area
                                    $this.trigger('efw.managedrolesarea');
                                }
                            },
                            opts
                        )
                    );

                    console.info(`Serialized: ${JSON.stringify(model)}`);
                });
            });
        });
    };

    // Interface for role managers
    $.fn.logon$rolemanagerarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.logon', function () {
                $this.trigger('efw.rolemanagerarea');
            });

            $this.on('efw.rolemanagerarea', function () {
                $this.empty();
                $this.append('<h1>For Role Managers</h1>');
                $this.append(`<p>Role Manager: ${GetUser()}</p>`);

                var $accordion = $(`<div id="rma-accordion" class="panel-group" role="tablist" aria-multiselectable="true"></div>`);
                $this.append($accordion);

                // Iterate over roles I manage. Usually at most one.
                GetMyManagedRoles(opts, /* each */ function (i, r) {
                    // build out a whole interface zone for this managed role.
                    var $accordionItem = $(`<div class="panel${i ? '' : ' panel-default'}"></div>`),
                        $accordionHeading = $(`<div class="panel-heading" role="tab" id="${r.Name}-ah"></div>`),
                        $accordionTitle = $(`<h2 class="panel-title role-block"><a  role="button" data-toggle="collapse" data-parent="#rma-accordion" href="#${r.Name}-ab" aria-expanded="${i ? false : true}" aria-controls="${r.Name}-ab">Role: "${r.Title}" (${r.Name})</a></h2>`),
                        $accordionCollapse = $(`<div id="${r.Name}-ab" class="panel-collapse collapse in${i ? '' : ' show'}" role="tabpanel" aria-labelledby="${r.Name}-ah"><div class="panel-body"></div></div>`),
                        $accordionBody = $accordionCollapse.find('.panel-body');

                    $accordionHeading.append($accordionTitle);
                    $accordionItem.append($accordionHeading);
                    $accordionItem.append($accordionCollapse);
                    $accordion.append($accordionItem);

                    // list members of the role.
                    $accordionBody.append(`<h3>Members</h3>`);
                    var $memberTable = $('<table class="user-role-table"><thead><tr><th>Member email</th><th></th><th></th></tr></thead><tbody></tbody></table>'),
                        $body = $memberTable.find('tbody');

                    $accordionBody.append($memberTable);

                    // "Add a Member" form
                    $accordionBody.append(`<h3>Add a Member</h3>`);
                    var $form = $('<form class="formarea" action="#"></form>');

                    $form.append(`<div class="form-group"><label for="${r.Name}_rmmemail" class="required">Account Email (new or existing):</label><input name="Email" type="email" required="required" id="${r.Name}_rmmemail" class="form-control" /></div>`);
                    $form.append(`<div class="form-group"><label for="${r.Name}_rmmorg">Organization (if creating new account):</label><select name="OrganizationId" class="form-control" id="${r.Name}_rmmorg"><option value="-1">None of these</option></select></div>`);

                    var $orgSelect = $form.find('select[name=OrganizationId]');

                    GetOrgs(opts, function (err, data) {
                        $.each(data, function (i, d) {
                            $orgSelect.append('<option value="' + d["Id"] + '">' + d["Display"] + '</option>');
                        });
                    });
                    $form.append(`<div class="form-group"><label for="${r.Name}_rmmmemail">Your Email:</label><input type="email" name="ManagerEmail" id="${r.Name}_rmmmemail" class="rmm-input form-control" disabled required="required" value="${GetUser()}" /></div>`);
                    $form.append(`<div class="form-group"><label for="${r.Name}_rmmmname" class="required">Your Name:</label><input type="text" name="ManagerName" id="${r.Name}_rmmmname" class="form-control" required="required" /></div>`);
                    $form.append(`<input type="hidden" id="${r.Name}_rmmrpu" name="ResetPasswordUrl" value="${window.location.protocol}//${window.location.host}?_reset=true" />`);
                    $form.append('<div class="form-group"><button class="btn btn-primary">Submit</button></div>');
                    $form.append('<div><ul class="status"></ul></div>');

                    var $status = $form.find('.status');

                    $accordionBody.append($form);
                    $form.logon$formvalid();
                    $form.off('submit').on('submit', function (event) {
                        $status.empty();
                        // prevent actual submission of the form
                        event.preventDefault();
                        event.stopPropagation();

                        var valid = $form.logon$formvalid('check'),
                            email = ($form.find('[name=Email]').val() || '').toLowerCase(),
                            role = r.Name,
                            managedByRole = r.ManagedByRole;

                        if (!valid)
                            return;

                        var _AddToRole = function (message) {
                            return function () {
                                var success = function () {
                                    AppendError($status, message);
                                    $memberTable.trigger('efw.rolemanagerarea-members');    // member list likely changed
                                    $accordionBody.find('.rolescommsarea').trigger('efw.selectrolescomms'); // and certainly comms area
                                    // instead of resetting the form here, we want to clear the email field
                                    $form.find('[name=Email]').val('').trigger('focus');
                                    // reset visual form validation
                                    _.defer(function () { $form.removeClass('was-validated') });
                                };

                                // treat 409 (already in role) equivalent to success.
                                AddToRole(
                                    {
                                        User: email,
                                        Role: role,
                                        Notify: true,
                                        ManagerEmail: $form.find('[name=ManagerEmail]').val(),
                                        ManagerName: $form.find('[name=ManagerName]').val()
                                    },
                                    $.extend({
                                        successHandler: success,
                                        dispatchMap: {
                                            200: success,
                                            409: success
                                        }
                                    }, opts)
                                );
                            }
                        };

                        // commit registration
                        DoRegisterProvisional(
                            {
                                Email: email,
                                OrganizationId: $orgSelect.val() || -1,
                                ManagerEmail: $form.find('[name=ManagerEmail]').val(),
                                ManagerName: $form.find('[name=ManagerName]').val(),
                                ResetPasswordUrl: $form.find('[name=ResetPasswordUrl]').val(),
                                LanguageID: $.fn.efw$local.lang(),
                                SiteRegistered: opts.site || -1
                            },
                            // handlers. 
                            $.extend({
                                dialogArea: $accordionBody,
                                statusArea: $status,
                                successHandler: _AddToRole(`Added new account ${email} to ${role}.`),
                                dispatchMap: {
                                    200: _AddToRole(`Added new account ${email} to ${role}.`),
                                    409: _AddToRole(`Added existing account ${email} to ${role}`),
                                    401: _logonFail, 403: _logonFail
                                }
                            }, opts)
                        );
                    });

                    // member communication area
                    $accordionBody.append('<h3>Communicate with Your Members</h3>');
                    $accordionBody.append('<div class="rolescommsarea"></div>');  // to fill in with logon$rolescommsarea()

                    $accordionBody.find('.rolescommsarea').logon$rolescommsarea($.extend(
                        options,
                        {
                            role: r.Name,
                            allowInterface: true
                        }
                    ));
                    $accordionBody.find('.rolescommsarea').trigger('efw.createrolescommsarea');

                    // Pass on the role to someone else
                    // this function only available if we're actually in the manager role.
                    if (IsInRole(r.ManagedByRole)) {
                        var $passOnForm = $('<form class="formarea" action=#"></form>');
                        $passOnForm.append('<h3>Pass on this Management Role to Someone Else</h3>');
                        $passOnForm.append(`<p>Submit this form to give up the role ${r.ManagedByRole} and give it to someone else.`);
                        $passOnForm.append(`<div class="form-group"><label for="${r.Name}_rmmnemail">New Manager's Email:</label><input type="email" id="${r.Name}_rmmmemail" name="NewManagerEmail" class="rmm-input form-control" required="required" /></div>`);
                        $passOnForm.append('<div class="form-group"><label for="${r.Name}_rmmorg">Organization:</label><select name="OrganizationId" class="rpr-org form-control" id="${r.Name}_rmmorg"><option value="-1">None of these</option></select></div>');

                        var $orgSelect = $passOnForm.find('select[name=OrganizationId]');

                        GetOrgs(opts, function (err, data) {
                            $.each(data, function (i, d) {
                                $orgSelect.append('<option value="' + d["Id"] + '">' + d["Display"] + '</option>');
                            });
                        });
                        $passOnForm.append(`<input type="hidden" name="RoleName" value="${r.ManagedByRole}" />`);
                        $passOnForm.append(`<input type="hidden" name="ResetPasswordUrl" value="${window.location.protocol}//${window.location.host}?_reset=true" />`);
                        $passOnForm.append('<div class="form-group"><button class="btn btn-primary">Submit</button></div>');
                        $passOnForm.append('<div><ul class="status"></ul></div>');
                        $accordionBody.append($passOnForm);
                        $passOnForm.logon$formvalid();

                        var $email = $passOnForm.find('[name=NewManagerEmail]');
                        $email.on('blur', function (e) {
                            // custom validity check: Can't pass on role to yourself.
                            if ($email.val().trim().toLowerCase() === GetUser().toLowerCase()) {
                                $email[0].setCustomValidity("You can't pass on the role to yourself.");
                            } else {
                                $email[0].setCustomValidity("");
                            }
                        });

                        $passOnForm.off('submit').on('submit', function (e) {
                            $status.empty();
                            // prevent actual submission of the form
                            e.preventDefault();
                            e.stopPropagation()

                            var valid = $passOnForm.logon$formvalid('check'),                                
                                email = ($email.val() || '').toLowerCase(),
                                role = r.Name,
                                resetPasswordUrl = $passOnForm.find('[name=ResetPasswordUrl').val();

                            if (!valid)
                                return;


                            var _PassOnRole = function (message) {
                                return function () {
                                    var success = function () {
                                        // If we've passed on the management role, likely the whole page layout is changed
                                        window.location.reload();
                                    };

                                    // treat 409 (already in role) equivalent to success.
                                    PassOnRole(
                                        {
                                            NewManagerEmail: email,
                                            RoleName: r.ManagedByRole,
                                            ResetPasswordUrl: resetPasswordUrl,
                                            LanguageID: $.fn.efw$local.lang()
                                        },
                                        $.extend({
                                            successHandler: success,
                                            dispatchMap: {
                                                200: success,
                                                409: success
                                            }
                                        }, opts)
                                    );
                                }
                            };

                            var _RegisterProvisional = function () {
                                // commit registration
                                DoRegisterProvisional(
                                    {
                                        Email: email,
                                        ManagerEmail: GetUser(),
                                        ManagerName: GetUser(),
                                        ResetPasswordUrl: resetPasswordUrl,
                                        LanguageID: $.fn.efw$local.lang(),
                                        SiteRegistered: opts.site || -1,
                                        Organizationid: $orgSelect.val() || -1 
                                    },
                                    // handlers. 
                                    $.extend({
                                        dialogArea: $accordionBody,
                                        statusArea: $status,
                                        successHandler: _PassOnRole(`Passed on role ${role} to new account ${email}.`),
                                        dispatchMap: {
                                            200: _PassOnRole(`Passed on role ${role} to new account ${email}.`),
                                            409: _PassOnRole(`Passed on role ${role} to existing account ${email}`),
                                            401: _logonFail, 403: _logonFail
                                        }
                                    }, opts)
                                );
                            };

                            // ask explicit validation
                            if (window.confirm(`Are you sure that you want to give up the the management role ${r.ManagedByRole} and give it to ${email}?`)) {
                                _RegisterProvisional();
                            };
                        });
                    }

                    $memberTable.on('efw.rolemanagerarea-members', function () {
                        $body.empty();

                        GetRoleMembers(r.Name,
                            opts,
                            function /* each */(i, u) {
                                $body.append(`<tr><td>${u}</td><td><a href="#" class="mr-remove" data-user="${u}">Remove from Role</a></td><td><a href="#" class="mr-disable" data-user="${u}">Suspend Account</a></td></tr>`);
                            },
                            function /* empty */() {
                                $body.append(`<tr><td colspan="3"><em>This role has no members.</em></td></tr>`);
                            },
                            function /* finally */() {
                                $body.find('.mr-remove').off('click').on('click', function (e) {
                                    e.preventDefault();
                                    var $model = {
                                        User: $(this).data('user'),
                                        Role: r.Name
                                    };
                                    console.info(`Remove ${$model.User} from role ${$model.Role}`);
                                    if (window.confirm(`Really remove ${$model.User} from role ${$model.Role}?`)) {
                                        RemoveFromRole(
                                            $model,
                                            $.extend({}, opts,
                                                {
                                                    successHandler: function () {
                                                        AppendError($status, `Removed ${$model.User} from role ${$model.Role}.`);
                                                        //console.info('refreshing...');
                                                        $memberTable.trigger('efw.rolemanagerarea-members');
                                                        $accordionBody.find('.rolescommsarea').trigger('efw.selectrolescomms');
                                                    }
                                                }
                                            )
                                        );
                                    };
                                });
                                $body.find('.mr-disable').off('click').on('click', function (e) {
                                    e.preventDefault();
                                    var $model = {
                                        Email: $(this).data('user'),
                                        Disable: true
                                    };
                                    console.info(`Suspend account: ${$model.Email}`);
                                    if (window.confirm(`Really suspend account of ${$model.Email}? Only a Vault administrator can undo this action.`)) {
                                        DoDisableAccount($model,
                                            // handlers. 
                                            $.extend({}, opts, {
                                                successHandler: function () {
                                                    AppendError($status, `Suspended account ${$model.Email}.`);
                                                    // If we've passed on the management role, likely the whole page layout is changed
                                                    window.location.reload();
                                                }
                                            })
                                        );
                                    };
                                });
                            }
                        );
                    });

                    $memberTable.trigger('efw.rolemanagerarea-members');
                }, /* empty */ function () {
                    $this.append("<p><em>You don't appear to be a role manager. If you've recently been made a role manager, you should <a href=\"#\" class=\"logout\">log out and back in.</em></p>");
                    $this.find('.logout').off('click').on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        DoLogoff();
                        $.gevent.publish('efw.logoutmanual');
                    });
                });
            });
        });
    };

    // Request roles dialog
    $.fn.logon$requestroleform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $roles = [];
            var $form = $this.find('form'),
                $existingRoles = $this.find('#rrroles'),
                $noExistingRoles = $this.find('.no-roles'),
                $requestableRoles = $this.find('.rrrequests'),
                $noRequestableRoles = $this.find('.no-roles-requestable'),
                $reasonWrap = $this.find('.rrreason-wrap'),
                $supervisorWrap = $this.find('.rrsupervisor-area'),
                $supervisorName = $this.find('#rrsupervisor-name'),
                $supervisorEmail = $this.find('#rrsupervisor-email'),
                $reason = $this.find('#rrreason'),
                $submit = $this.find('#rrsubmit'),
                manageRolesFilter = opts.manageRolesFilter || undefined;

            // prep the form
            $form.logon$formvalid();

            // reset form state on close
            $this.on('hide.bs.modal', function () {
                $this.find(':checkbox').prop('checked', '');
                $reasonWrap.hide();
            });
            $.gevent.subscribe($this, 'efw.logon', function () {
                $existingRoles.empty();
                $requestableRoles.empty();
                $submit.addClass('disabled');
                $noExistingRoles.hide();
                $noRequestableRoles.hide();
                $reasonWrap.hide();
                $reason.val('');
                GetAllRoles(opts, function (data) {
                    $roles = data;
                    // list the roles the logged-in user has.
                    $.each($roles, function (i, r) {
                        if (IsInRole(r.Name)) {
                            var item = $('<li title="' + (r.Description || r.Name) + '" class="help-over"></li>').text(r.Title || r.Name);
                            $existingRoles.append(item);
                        }
                    });
                    $noExistingRoles.toggle($existingRoles.children().length == 0);

                    // list all requestable roles [the user isn't in.]
                    $.each($roles, function (i, r) {
                        if (r.Requestable) {
                            var id = 'role_' + _identifier(r.Name);
                            var hasRole = IsInRole(r.Name);
                            // filter the list of requestable roles if requested. Set the manageRolesFilter by setting the SiteOptOverrides knob. Example: hideManageRoles=false|manageRolesFilter=['provider-az']
                            //console.info(`filtering ${r.Name} among ${manageRolesFilter}`);
                            if (manageRolesFilter && manageRolesFilter.includes && !manageRolesFilter.includes(r.Name))
                                return;
                            var item = $('<div class="role-request form-check' + (hasRole ? ' disabled' : '') + '"><input class="role-request-item form-check-input' + (r.RequiresSupervisor ? ' requires-supervisor' : '') + '" type="checkbox" name="' + _identifier(r.Name) + '" id="' + id + '" data-role="' + r.Name + '"' + (hasRole ? ' disabled="disabled" checked="checked"' : '') + ' /> <label class="form-check-label" data-toggle="tooltip" data-placement="right" for="' + id + '" title="' + (r.Description || r.Name) + '" >' + (r.Title || r.Name) + '</label></div>');
                            $requestableRoles.append(item);
                        }
                    });
                    $noRequestableRoles.toggle($requestableRoles.children().length == 0);

                    // enable tooltips
                    $requestableRoles.find('[data-toggle="tooltip"]').tooltip();

                    // disable/enable submit button based on whether any checkboxes are selected.
                    $('.role-request-item').on('change', function () {
                        $submit.toggleClass('disabled', ($('.role-request-item:checked').not(':disabled').length == 0));
                        // show the Supervisor area only if any roles requiring supervisor information are selected.
                        var requiresSupervisor = $('.role-request-item.requires-supervisor:checked').not(':disabled').length > 0;
                        $supervisorWrap.toggle(requiresSupervisor);
                        $supervisorEmail.prop('required', requiresSupervisor);
                        $supervisorName.prop('required', requiresSupervisor);
                        //console.log('x.changed');
                        $reasonWrap.toggle(($('.role-request-item:checked').not(':disabled').length > 0));
                    });

                    $('.role-request-item').first().trigger('change');

                    $submit.on('click', function (e) {
                        e.preventDefault();
                        if ($(this).hasClass('disabled'))
                            return;

                        var valid = $form.logon$formvalid('check');

                        if (!valid)
                            return;

                        $(this).addClass('disabled');   // debounce

                        // serialize the checkboxes
                        var requestModels = []; // array of models
                        $this.find('.role-request-item:checked').not(':disabled').each(function (i, c) {
                            requestModels.push({
                                RequestedRoleName: $(c).data('role'),
                                RequestReason: $reason.val(),
                                SupervisorName: $supervisorWrap.is(':visible') ? $supervisorName.val() : '',
                                SupervisorEmail: $supervisorWrap.is(':visible') ? $supervisorEmail.val() : ''
                            });
                        });
                        //console.log('Requesting roles: ' + JSON.stringify(requestModels));

                        RequestRoles(
                            requestModels,                            
                            $.extend({
                                dialogArea: $this
                            }, opts)
                        );
                    });
                });
            });
        });
    };

    $.fn.logon$accountsarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        if (!IsLoggedIn())
            return;

        return this.each(function () {
            var $this = $(this);
            GetNumAccounts(opts, function (data) {
                $this.text(data.Count + " accounts as of " + new Date().toDateString());
            });
        });
    };

    $.fn.logon$unsubscribeform = function (options) {
        //console.info('logon$unsubscribeform');
        var opts = $.extend({}, $.fn.logon.defaults, options);

        // read parameters off querystring
        const url = new URL(window.location.href),
            params = {},
            KEYS = ['k', 'h', 't'],
            TYPES = ['ap']; // known lists to unsubscribe from

        KEYS.forEach((key) => {
            params[key] = url.searchParams.get(key);
        });

        // doesn't require current login
        return this.each(function () {
            var $this = $(this);

            // validate params
            if (!KEYS.every((k) => {
                return !!params[k];
            })) {
                // error message.
                console.log('logon$unsubscribeform: Missing parameters.');
                return;
            }

            var $form = $this.find('form');
            var $lists = $this.find('.ulists');
            var $email = $this.find('[name=uemail]');
            var $hash = $this.find('[name=uhash]');
            var $submit = $this.find('[name=usubmit]');
            var $status = $this.find('[name=ustatus]');

            // email label.
            $email.val(params['k']);

            // checkboxes for each known list type
            TYPES.forEach((t) => {
                $lists.append(`<div class="form-check"><input class="form-check-input utype" type="checkbox" checked="${params['t'] == t}" id="utype-${t}" data-type="${params['t']}" /><label class="form-check-label" for="utype-${t}">${('unsubscribe.type.' + t)._local()}</label></div>`);
            });

            // change handler for checkboxes - toggle the submit button appropriately. Use delegated event handler for this dynamic checkbox
            $form.on('change', '.utype', (e) => {
                //console.info('check/changed');
                $submit.prop('disabled', $('.utype:checked').length === 0);
            });

            // hidden input for the hash.
            $hash.val(params['h']);

            // submit handler.
            $submit.on('click', (e) => {
                e.preventDefault();

                var model = {
                    email: $email.val(),
                    hash: $hash.val(),
                    type: $form.find('.utype:checked').data('type')
                }
                //console.log(`Submit! ${JSON.stringify(model)}`);

                DoUnsubscribe(model,
                    $.extend({
                        dialogArea: $this,
                        statusArea: $status,
                        successHandler: function (jqXHR, status, $handlers) {
                            $handlers.dialogArea.modal('hide');
                            // ...expects model { title, message, timeout }; will localize the strings
                            $.gevent.publish('efw.result', {
                                title: 'general.success.title',
                                message: 'unsubscribe.success.message',
                                timeout: 5000
                            });
                        },
                        dispatchMap: {
                            401: function () { AppendError($status, "unsubscribe.fail.message"._local()); $status.find('a.dlgPop').efw$dialogPop(); },
                            400: function () { AppendError($status, "unsubscribe.fail.message"._local()); $status.find('a.dlgPop').efw$dialogPop(); }
                        }
                    }, opts)
                )

            });

            // launch this dialog if needed!
            $.gevent.subscribe($this, 'ready-efw', function () {
                if ("true" === getUrlParameter('unsubscribe')) {
                    $this.modal('show');
                }
            });
        });
    };

    // Plugin to handle pro session form - shown when Vault link is clicked with pro intention, and user is a pro
    $.fn.logon$prosessionform = function (options) {
        //console.info('logon$prosessionform');
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this),
                $select = $this.find('#pscontact'),
                $submit = $this.find('#pssubmit');

            // submit button clicked.
            $submit.off('click').on('click', function (e) {
                let href = $this.prop('href'),
                    target = $this.prop('target') || 'vault',
                    contactId = $select.find('option:selected').val();

                //console.info(`prosessionform: href ${href}; target: ${target}`);

                e.preventDefault();
                if (href && target) {
                    // we need to mangle the URL to add the pro session ID.
                    if (contactId !== '-1') {
                        target = `${target}_${contactId}`;    // separate tab per contact ID!
                        href = href.replace(/^(.*)(paths\/)(.*)$/, `$1$2contact/${contactId}/$3`);
                    }
                    _launchTab(href, target);
                }
                $this.modal('hide');
            });

            // modal opening.
            $this.on('show.bs.modal', function () {
                let href = $this.prop('href'),
                    target = $this.prop('target') || 'vault';

                // when dialog shown, populate the dropdown.
                $select.empty();
                // TODO: localize
                $select.append('<option value="-1">Use my own data</option>');

                $.ajax({
                    type: "GET",
                    url: `${opts.vaultService}/contact`,
                    data: "{}",
                    contentType: JSON_CONTENT,
                    dataType: "json",
                    headers: { "Authorization": "Bearer " + GetToken() },
                    processData: true
                }).done(function (data, textStatus, jqXHR) {
                    // if no data, simply proceed.
                    if (!_.isArray(data) || data.length === 0) {
                        if (href) {
                            _launchTab(href, target);
                        }
                        return;
                    }

                    // sort!
                    data.sort(function (a, b) {
                        let astr = `${a.firstName} ${a.lastName}`,
                            bstr = `${b.firstName} ${b.lastName}`;

                        return astr.localeCompare(bstr);
                    });
                    data.forEach(function (c) {
                        $select.append(`<option value="${c.id}">${c.firstName} ${c.lastName}</option>`);
                    });
                }).fail(function (jqXHR, textStatus /*, errThrown*/) {
                    console.error(`Error getting Vault contacts: ${textStatus}`);
                    // ...proceed

                    if (href) {
                        _launchTab(href, target);
                    }
                });
            });
        });
    }

    // dialog to show when session expires
    $.fn.logon$expiredform = function (/*options */) {
        return this.each(function () {
            var $this = $(this);

            $.gevent.subscribe($this, 'efw.expired', function () {
                $this.modal('show');
            });
        });
    }

    // Confirm that you want to disable your account
    $.fn.logon$confdisableform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this),
                $status = $this.find('.status');

            $this.find('#cdsubmit').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var $model = {
                    Email: GetUser(),
                    Disable: true
                };
                DoDisableAccount($model,
                    // handlers. 
                    $.extend({
                        dialogArea: $this,
                        statusArea: $status
                    }, opts)
                );
            });
        });
    }
    // add a new organization to the list
    $.fn.logon$addorgform = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $form = $this.find('form');
            var $key = $this.find('[name=aokey]');
            var $display = $this.find('[name=aodisplay]');
            var $submit = $this.find('[name=aosubmit]');
            var $status = $this.find('[name=aostatus]');

            // prep the form
            $form.logon$formvalid();

            // bind submit action
            $submit.bind("click", function (event) {
                event.preventDefault();
                event.stopPropagation();

                var valid = $form.logon$formvalid('check');

                if (!valid)
                    return;

                // Post the organization.
                AddOrg(
                    { Site: opts.site, Key: $key.val(), Display: $display.val() },
                    // handlers
                    $.extend({
                        dialogArea: $this,
                        statusArea: $status,
                        successHandler: function () {
                            $('.orgsarea').trigger('efw.orgs');
                            $this.modal('hide');
                        }
                    }, opts)
                );
                return;
            });
        });
    };

    // pop up the logon dialog if not logged in already
    $.fn.logon$logpop = function (/* options */) {
        return this.first().each(function () { // note once only
            setTimeout(
                function () {
                    if (!IsLoggedIn()) {
                        $('#logon').modal('show');
                    }
                }, 3000
             );
        });
    }

    $.fn.logon$favoritesarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $list = $this.find('.flist');
            var $none = $this.find('.nofavorites');
            var $tools = ($this.hasClass('tools'));
            var $title = $this.find('.personalModuleTitle');
            var $edit = $this.closest('.personalArea').find('.edit');

            $title.append($('<div class="spinner-wrap"><div class="spinner-border"></div></div>'));

            var $update = function () {
                $list.empty();
                $none.hide();
                $edit.addClass('disabled');
                if (IsLoggedIn()) {
                    GetFavorites(opts);
                }
            };

            var $render = function (e, data) {
                $title.removeClass('spin');
                $list.empty();
                if (null === data || undefined === data) {
                    $none.show();
                    $list.hide();
                    return;
                }

                $.each(arguments, function (i, d) {
                    if (0 == i)
                        return; // first argument = event
                    if (IsFavoriteTool(d) != $tools)
                        return; // looking for tools, or not
                    var title = d.title.replace(/:$/, ""); // eliminate trailing colon
                    $list.append('<li><a class="delete' + (d.isNew ? ' isNew' : '') + '" style="display:none;" title="' + "favorite.delete"._local() + '" href="javascript:void(0);" ref="' + d.id + '">' + (opts.omitImages ? '' : '<img src="/master_images/3/clear-all.svg" alt="" />') + '</a><a href="' + d.url + '" class="' + (d.isNew && !opts.omitImages ? 'is-new' : '') + '">' + title + '</a>' + '</li>');
                    //if (d.isNew)
                    //    $sawNew = true;
                    $list.show();
                    $none.hide();
                    $edit.removeClass('disabled');
                });

                $list.find('.delete').on('click', function (e) {
                    e.preventDefault();
                    DeleteFavorite($(this).attr('ref'), opts);
                }).toggle($editing);

                if ($list.is(":empty")) {
                    $none.show();
                    $list.hide();
                }
            };

            $.gevent.unsubscribe($edit, 'efw.favoritesSeek');   // make sure there's only one handler on this shared edit button
            $.gevent.subscribe($edit, 'efw.favoritesSeek', function () { $edit.addClass('disabled'); }); // ...last one wins
            $.gevent.subscribe($this, 'efw.favoritesSeek', function () { $title.addClass('spin'); }); 
            $.gevent.subscribe($this, 'efw.favoritesUpdate', $render);
            $.gevent.subscribe($this, 'efw.logon', $update);
            $.gevent.subscribe($this, 'efw.logout', $update);
        })
    };

    $.fn.logon$savedsessionarea = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            var $this = $(this);
            var $list = $this.find('.sslist');
            var $none = $this.find('.nosessions');
            var $some = $this.find('.hassessions');
            var $title = $this.find('.personalModuleTitle');
            var filter = $this.data('filter') + '';
            var $edit = $this.closest('.personalArea').find('.edit');

            $title.append($('<div class="spinner-wrap"><div class="spinner-border"></div></div>'));

            var $update = function () {
                $list.empty();
                $none.hide();
                $edit.addClass('disabled');
                if (IsLoggedIn()) {
                    GetSavedSessions(opts);
                }
            };

            var $render = function (e, data) {
                var sessionServiceHost = opts.sessionService.substring(0, opts.sessionService.indexOf('/planning/')),
                    foundAny = false;
                //console.log('sessionServiceHost: "' + sessionServiceHost + '"');
                $title.removeClass('spin');
                $list.empty();
                if (null === data || undefined === data) {
                    $none.show();
                    $some.hide();
                    $list.hide();
                    return;
                }
                $.each(arguments, function (i, d) {
                    if (0 == i)
                        return; // first agrument = event
                    if (d.IsOld)
                        return; // don't show old sessions
                    if (filter) {
                        if (d.SituationID !== filter)
                            return; // show only sessions matching the filter.
                    }
                    foundAny = true;
                    var $created = new Date(d.DateCreated + "Z"); // assumed UTC.
                    var $updated = new Date(d.DateSaved + "Z");
                    var sessionName = (filter ? '' : '<strong>' + d.SituationName + ': </strong>') + d.Name;    // no need to add situation when filtering.
                    var title = "savedsession.created"._local() + " " + $created.toLocaleDateString($.fn.efw$local.locale(), { timeZone: 'UTC' }) + " " + formatAMPM($created) + " " + d.TZAbbrev;
                    title += "; " + "savedsession.saved"._local() + " " + $updated.toLocaleDateString($.fn.efw$local.locale(), { timeZone: 'UTC' }) + " " + formatAMPM($updated) + " " + d.TZAbbrev;
                    // launch in a new tab if the link is offsite.
                    $list.append('<li><a class="delete' + (d.isNew ? ' isNew' : '') + '" title="' + "favorite.delete"._local() + '" href="javascript:void(0);" ref="' + d.SessionID + '" rel="' + d.SituationID + '">' + (opts.omitImages ? '' : '<img src="/master_images/3/clear-all.svg" alt="" />') + '</a><a href="' + sessionServiceHost + d.LaunchUrl + '" title="' + title + '"' + (sessionServiceHost.length > 0 ? ' target="_blank"' : '') + ' class="' + (d.IsNew && !opts.omitImages ? 'is-new' : '') + '">' + sessionName + '</a>' + '</li>');
                    $list.show();
                    $some.show();
                    $none.hide();
                    $edit.removeClass('disabled');
                });
                if (!foundAny) {
                    $none.show();
                }

                $list.find('.delete').on('click', function (e) {
                    e.preventDefault();
                    if (confirm("savedsession.confirmdelete"._local()))
                        DeleteSavedSession({ SituationID: $(this).attr('rel'), SessionID: $(this).attr('ref') }, opts);
                }).toggle($editing);
            };

            $.gevent.unsubscribe($edit, 'efw.sessionsSeek');   // make sure there's only one handler on this shared edit button
            $.gevent.subscribe($edit, 'efw.sessionsSeek', function () { $edit.addClass('disabled'); }); // ...last one wins
            $.gevent.subscribe($this, 'efw.sessionsSeek', function () { $title.addClass('spin'); });
            $.gevent.subscribe($this, 'efw.sessionsUpdate', $render);
            $.gevent.subscribe($this, 'efw.sessionsReset', $update);
            $.gevent.subscribe($this, 'efw.logon', $update);
            $.gevent.subscribe($this, 'efw.logout', $update);
        })
    };

    var _launchTab = function (url, name) {
        var win = window.open(url, name);
        if (win) {
            win.focus();
        }
    };

    // handle clicks on Vault links: offer to log in, then continue.
    $.fn.logon$vaultlink = function (options) {
        var opts = $.extend({}, $.fn.logon.defaults, options);

        return this.each(function () {
            $(this).off('click')
                .on('click', function (e) {
                    e.preventDefault();
                    var $this = $(e.currentTarget), // <a> tag to which the event is attached (e.target may be the text inside it)
                        href = $this.attr('href'),
                        proIntent = $this.data('proIntent'),
                        hasProSessionDialog = $('#proSession').length > 0,
                        launch = function () {
                            _launchTab(href, 'vault');
                        };

                    if (IsLoggedIn()) {
                        // Check: Are we in a pro role?
                        if (proIntent && _GetDataBoolean('hasProRole') && hasProSessionDialog) {
                            //console.info(`Has Pro role...intercept`);
                            $('#proSession')
                                .prop('href', href)   // attach data to modal
                                .prop('target', 'vault')
                                .modal('show');
                        } else {
                            // simply navigate (in new tab)
                            launch();
                        }
                    } else {
                        // remember our intent
                        $this.prop('intentClicked', true);
                        $.gevent.unsubscribe($this, 'efw.logon');
                        $.gevent.subscribe($this, 'efw.logon', function () {
                            if ($this.prop('intentClicked')) {
                                $this.prop('intentClicked', false);

                                // Check: Are we in a pro role?
                                if (proIntent && _GetDataBoolean('hasProRole') && hasProSessionDialog) {
                                    //console.info(`Has Pro role...intercept`);
                                    $('#proSession')
                                        .prop('href', href)   // attach data to modal
                                        .prop('target', 'vault')
                                        .modal('show');
                                } else {
                                    // simply navigate (in new tab)
                                    launch();
                                }
                            }
                        });
                        // offer logon.
                        $('#logon_or_register').modal('show');
                    }
                });

            // track events
            if ("undefined" !== typeof ($.fn.trackEvents)) {
                $(this).trackEvents({ category: "Clicks", action: "VaultLaunch", label: $(this).attr('href') });
            }
        });
    }

    // Time format utility
    function formatAMPM(date) {
        var hours = date.getUTCHours();
        var minutes = date.getUTCMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    $.fn.logon$editcontrols = function (/*options*/) {

        return this.each(function () {
            var $this = $(this);
            var $personal = $this.closest('.personalArea');
            var $edit = $personal.find('.edit');
            var $cancel = $personal.find('.cancel');

            $edit.on('click', function (e) {
                e.preventDefault();
                $editing = true;
                $personal.find('.delete').show();
                $edit.hide();
                $cancel.show();
            });
            $cancel.on('click', function (e) {
                e.preventDefault();
                $editing = false;
                $personal.find('.delete').hide();
                $cancel.hide();
                $edit.show();
            });

        });
    }

    $.fn.logon$isLoggedIn = function () {
        return IsLoggedIn();
    };

    $.fn.logon$getData = function (k) {
        if (!IsLoggedIn())
            return null;

        return _GetDataItem(k);
    };

    $.fn.logon$getToken = function () {
        return GetToken();
    };

    $.fn.logon$getTargetView = function (def) {
        if (window.location.hash)
            // use leading slash if caller does
            return ('/' == def.charAt(0) ? '/' : '') + window.location.hash + window.location.search;
        return def;
    };

    $.fn.logon$getTargetView2 = function (def) {
        var slash = ('/' === def.charAt(0) ? '/' : '');
        if (slash)
            def = def.substring(1);

        if (window.location.hash)
            // use leading slash if caller does
            return slash + window.location.search + window.location.hash;
        else if (window.location.search)
            return slash + window.location.search + def;
        else
            return def;
    };

    $.fn.logon$updateVaultState = function (model) {
        if (!IsLoggedIn())
            return;

        if (!model.service)
            return;

        var proSessionID = GetProSessionID(),
            endPoint = model.service + "/user/state" + (proSessionID ? "/sessionContext/" + proSessionID : "");

        $.ajax({
            type: "PUT",
            url: endPoint,
            data: JSON.stringify(model),
            contentType: JSON_CONTENT,
            dataType: "json",
            processData: true,
            headers: { "Authorization": "Bearer " + GetToken() }
        }).done(function (data, textStatus, jqXHR) {
            // success with status = 200: we changed the state
            //console.log('updateVaultState: ' + jqXHR.status);
            if (200 == jqXHR.status) {
                // note the event in analytics
                var action = 'completed' + (model.calcDetail ? '.' + model.calcDetail : '');
                if (window.gtag) {
                    window.gtag('event', 'vault.event', { action: action, label: 'tryEstimatorAVY' });
                } else {
                    window._gaq && window._gaq.push(['_trackEvent', 'Clicks', action, 'tryEstimatorAVY', -1, true]);
                }
                // pop up vault success dialog
                //$('#vaultCalcSuccess').modal('show');
            }
            // success with status = 204: no state change
        }).always(function () {
            //console.log("/user/state: " + JSON.stringify(model));
        });
    };

    // call to open a Vault dialog to share a session.
    $.fn.logon$shareSessionData = { share: {}, merge: {} };

    $.fn.logon$shareSession = function (/* shareModel, mergeModel */) {
        var cloneSessionCall = window.location.toString().replace(/([^?]*)\?(.*)/, '$1/CloneSession?$2');
        $.ajax({
            cache: false,
            type: 'POST',
            url: cloneSessionCall,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: function (result) {
                var data = JSON.parse(result.d);
                $.fn.logon$shareSessionData.share = { type: 'session', detail: data.name };
                $.fn.logon$shareSessionData.merge = { shareLink: data.url };
                $(VAULT_SHARE_SESSION_DIALOG).modal('show');
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.error("Error in login$shareSession: " + textStatus + ": " + errorThrown);
            }
        });
    };

    // logon to the system if instructed to by the URL
    const LogonFromUrl = function (handlers, callback) {
        var user = getUrlParameter('username'),
            pass = getUrlParameter('password'),
            redirect = getUrlParameter('redirect') || '/',
            passCode = getUrlParameter('pc'),
            $handlers = $.extend({}, $standardHandlers, handlers),
            $model = { username: user, password: pass };

        if (IsLoggedIn()) {
            if (callback)
                callback();
            return;
        }

        if (user.length > 0 && pass.length > 0) {
            DoLogon($model, $handlers, function () {
                if (IsLoggedIn()) {
                    window.location = redirect; // redirect, no callback
                }
            });
        } else if (passCode.length > 0) {
            // Logon using a passcode.
            GetPassCode(handlers, passCode, function (/* data */) {
                //console.log('GetPassCode:' + data.toString());
                if (callback)
                    callback();
            }, function () {
                if (callback)
                    callback();
            });
        } else {
            if (callback)
                callback();
            return;
        }
    },
        //PostPassCode = function (opts, success, error) {
        //    $.ajax({
        //        type: "POST",
        //        url: opts.logonService + "/api/PassCode",
        //        dataType: "json",
        //        contentType: JSON_CONTENT,
        //        data: JSON.stringify({ data: JSON.stringify(_GetLocalRaw(RAW_KEY)) }),  // data is string
        //        headers: { "Authorization": "Bearer " + GetToken() },
        //        success: function (data) {
        //            success(data);
        //        },
        //        error: function (jqXHR, status, errorThrown) {
        //            error(jqXHR, status, errorThrown);
        //        }
        //    });
        //},
        GetPassCode = function (opts, pc, success, error) {
            $.ajax({
                type: "GET",
                url: opts.logonService + "/api/PassCode/" + pc,
                dataType: "json",
                success: function (data) {
                    success(data);
                },
                error: function (jqXHR, status, errorThrown) {
                    error(jqXHR, status, errorThrown);
                }
            });

        };
    //LinkPassCodes = function (opts) {
    //    $("a[href^='https://preview2-mn.hb101.org']").each(function () {
    //        var $this = $(this);
    //        $this.click(function (e) {
    //            var $dest = $this.attr('href');
    //            e.preventDefault();
    //            PostPassCode(opts,
    //                function (data) {
    //                    if (null == data || undefined === data)
    //                        window.location = $dest;    // whoops = old click handler

    //                    window.location = $dest + "?pc=" + data.toString();
    //                },
    //                function (jqXHR, status, errorThrown) {
    //                    window.location = $dest;    // whoops = old click handler
    //                }
    //            );
    //        });
    //    });
    //    }
    //    ;

    // force new login
    $.fn.logon$sessionExpired = function () {
        DoLogoff();
        $.gevent.publish("efw.expired");
    };

    // call this one-time to set all UI state
    const TestLogon = function (options) {
        var opts = $.extend({
            successHandler: _nullHandler,
            dispatchMap: { 400: _alertFail, 401: _alertFail, 403: _alertFail }
        }, $.fn.logon.defaults, options);

        // notice expired token.
        var $expiredTimer;
        $.gevent.subscribe($('body'), 'efw.logon', function () {
            var $expires = _GetDataItem(EXPIRES_KEY),
                $now = new Date(),
                $expiresTime = new Date($expires);

            //console.log('Expiration time: ' + $expiresTime.getTime());
            //console.log('Now: ' + $now.getTime());
            var $timeout = $expiresTime.getTime() - $now.getTime() - (1000 * 60 * 4);   // 4 minutes early
            //console.log('Expiration date: ' + $expires + '; timeout = ' + $timeout);
            if ($timeout < 0) {
                // ignore this whole logic if there are dialogs open already.
                if (logon$anyDialogs())
                    return;
                $.fn.logon$sessionExpired();
            } else {
                $expiredTimer = setTimeout(function () {
                    //console.log('Expired!');
                    $.fn.logon$sessionExpired();
                }, $timeout);
            }
        });
        $.gevent.subscribe($('body'), 'efw.logout', function () {
            if ($expiredTimer) {
                clearTimeout($expiredTimer);
                $expiredTimer = null;
            }
        });

        //LinkPassCodes(opts);

        // log in if instructed to on URL. Logon may be asynchronous
        LogonFromUrl(opts, function () {

            if (IsLoggedIn()) {
                TrackOrgVariable();
                SetLogonCookie(_GetLocalData());
                $.gevent.publish('efw.logon');
            } else {
                KillLogonCookie();  // sometimes hangs out too long. Leave the org variable and key though.
                $.gevent.publish('efw.logout');
                $.gevent.publish('efw.navigateloggedout');  // navigated to a page and are logged out. We can notice this if we want to be logged in.
            }

        });
    };
    var logon$anyDialogs = function () {
        return $('.modal:visible').length && $('body').hasClass('modal-open');
    };

    // function to notice places where we want to offer logon.
    var logon$offerlogon = function () {
        // uses the URL interface. Fail gracefully if not present.
        try {
            // get notified when we navigate and are logged out.
            $.gevent.subscribe($('body'), 'efw.navigateloggedout', function () {
                //console.info('efw.navigateloggedout');
                var url = new URL(window.location);

                // ignore this whole logic if there are dialogs open already.
                if (logon$anyDialogs())
                    return;

                // look at elements in OFFER_LOGON_URL structure
                if (_.some(OFFER_LOGON_URL, function (e) {
                    return (e.pathname === url.pathname // pathname exact match
                        && (!e.requireHash || !!url.hash)); // either no requireHash condition; or URL has a hash
                })) {
                    //console.info('...navigation to an offer-page');
                    // make the offer.
                    $('#logon_or_register').modal('show');
                }
            });
        } catch {
            // let it go.
        }
    };

    // global initialization call.
    $.fn.logon$init = function (options) {
        // activate localization system.
        $('.local').efw$local(options);
        // activate forms.
        $('.logarea').logon$logarea(options);
        $('#logon').logon$logonform(options);
        $('#register').logon$registerform(options);
        $('#logon_or_register').logon$orregister(options);
        $('#profile').logon$profileform(options);
        $('#forgot').logon$forgotform(options);
        $('#resetpass').logon$resetpassform(options);
        $('#changepass').logon$changepassform(options);
        $('#requestrole').logon$requestroleform(options);
        $('#confemail').logon$confirmemailform(options);
        $('#confdisable').logon$confdisableform(options);
        $('#expired').logon$expiredform(options);
        $('#addorg').logon$addorgform(options);
        $('#result').logon$result(options);
        $('#unsubscribe').logon$unsubscribeform(options);
        $('#proSession').logon$prosessionform(options);
        $('.favoriteUI').logon$favoriteui(options);
        $('.favarea').logon$favoritesarea(options);
        $('.savedsessionarea').logon$savedsessionarea(options);
        $('.editControl').logon$editcontrols(options);
        $('.roles2area').logon$userroles2area(options);
        $('.rolerequestsarea').logon$rolerequestsarea(options);
        $('.registerprovisionalarea').logon$registerprovisionalarea(options);
        $('.rolescommsarea').logon$rolescommsarea(options);
        $('.managedrolesarea').logon$managedrolesarea(options);
        $('.rolemanagerarea').logon$rolemanagerarea(options);
        $('.accountsarea').logon$accountsarea(options);
        $('[href*="/vault.htm#"]').logon$vaultlink(options);    // any link to the Vault with a hash
        logon$offerlogon();

        // fire event for anybody who's waiting for form setup. It's safe now.
        _.defer(function () {
            $.gevent.publish('ready-efw');
        });

        var _matchEqualVal = function ($a, $b) {
            if ($b.val() == $a.val()) {
                $a[0].setCustomValidity('');
            } else {
                $a[0].setCustomValidity('validate.equalTo'._local());
            }
        }

        // handle password match validation in all forms
        $('input[data-equal-to]').on('change', function () {
            var $test = $(this),
                $match = $($test.data('equal-to'));
            _matchEqualVal($test, $match);
            //if ($test.val() == $match.val()) {
            //    this.setCustomValidity('');
            //} else {
            //    this.setCustomValidity('validate.equalTo'._local());
            //}
            // ...but also: make sure things get revalidated if the match changes
            $match.off('change.match').on('change.match', function () {
                _matchEqualVal($test, $match);
            });
        });

        // enable tooltips everywhere
        $('[data-toggle="tooltip"]').tooltip();

        // check if we're already logged in.
        TestLogon(options);
    };

    // efw$broadcast obsolete
    //$.fn.logon$listen = function () {
    //    // listen for broadcast logon events.
    //    var allowedOrigins = [/db101\.org$/, /hb101\.org$/, /eightfoldway\.com$/, /disabilityhubmn\.org$/];
    //    $.fn.efw$broadcast.listenCommand(allowedOrigins, function (verb, key, value) {
    //        //console.log('listener: ' + verb + ', ' + key);
    //        switch (verb) {
    //            case 'storeLocal':
    //                localStorage.setItem(key, value);
    //                if (TOKEN_KEY === key) {
    //                    try {
    //                        // interpret the value as JSON
    //                        var data = JSON.parse(value || '');
    //                        // peel out the access token
    //                        if (data && data[ACCESS_TOKEN_KEY]) {
    //                            Cookies.set(LOGON_COOKIE, data[ACCESS_TOKEN_KEY], LOGON_SCOPE);
    //                        }
    //                    } catch {
    //                        // who knows?
    //                    }
    //                }
    //                break;
    //            case 'deleteLocal':
    //                localStorage.removeItem(key);
    //                if (TOKEN_KEY === key) {
    //                    Cookies.remove(LOGON_COOKIE, LOGON_SCOPE);
    //                }
    //                break;
    //        }
    //    });
    //};

    // default option parameters
    $.fn.logon.defaults = $.extend({}, { site: -1, stateCode: '' }, window.logon$services || {});
})(jQuery);
